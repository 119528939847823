<template>
    <div class="part-server-container" style="width: 1170px; margin: 0px auto;">
      <div @mousemove="active = index" v-for="(item, index) in bgList" :class="{'show-large-content': active == index, 'show-content': active != index}" :style="{backgroundImage: active == index ? 'url(' +  require('../assets/' + item.abgImg) + ')' : 'url(' +  require('../assets/' + item.bgImg) + ')'}" :key="index">
      <!-- <div @mouseenter="active = index" v-for="(item, index) in bgList" :class="{'show-large-content': active == index, 'show-content': active != index}" :style="active == index? {backgroundImage: 'url(' +  require('../assets/' + item.abgImg) + ')'} : {backgroundImage: 'url(' +  require('../assets/' + item.bgImg) + ')'}" :key="index"> -->
        <p class="title" :class="{show: index == active}">
          {{ item.title }}
        </p>
        <p class="desc" v-show="active == index">{{ item.dec }}</p>
        <p class="sub-title" :class="{show: active == index}" v-for="(items, indexs) in item.sub" :key="indexs" v-html="items"></p>
      </div>
    </div>
    <div class="china">
      <img :src="require('../assets/' + '中国地图.svg')" style="position: absolute; left: 0; top: 2%; right: 0; margin: auto;">
      <div style="height:20px"></div>
      <div class="china-one">覆盖全国的边缘计算网络</div>
      <div class="china-two font">UlTIMATEEXPERIENCE</div>
      <div class="china-tree">覆盖全国主要地区，部署在更靠近用户的位置，支持资源弹性伸缩、全局智能调度、可视化数据运营和完善的服务。</div>
      <div style="width: 1170px; margin: 0px auto;">
        <div class="china-flex" ref="target" >
          <div class="china-cont" v-for="(item,index) in NumList" :key="index">
            <el-statistic
                value-style="
                color: #00eec2;
                text-align: center;
                font-family: DIN Alternate, DIN Alternate;
                font-size: 52px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;"
                :value="outputValue[index]"
                group-separator=""
            >
                <template #suffix>
                    <span class="data-icon">{{ item.tag }}</span>
                </template>
            </el-statistic>
            <div class="china-bom">{{item.value}}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'PartThree',
};
</script>
<script setup>

import { reactive, ref } from 'vue';
import { useTransition, useIntersectionObserver } from '@vueuse/core';

const target = ref()
const source = ref([0, 0, 0, 0]);
const targetIsVisible = ref(false);
const NumList = reactive([{ name: '280', value: '地区覆盖', tag: '+' }, { name: '30', value: '带宽规模', tag: 'T' }, { name: '1000', value: '区县覆盖', tag: '+' }, { name: '99', value: '平台可用性', tag: '.99%' },])
const active = ref(0)
const outputValue = useTransition(source, {
    duration: 1000,
});
const { stop } = useIntersectionObserver(target, ([{ isIntersecting }], observerElement) => {
    targetIsVisible.value = isIntersecting;
    if (isIntersecting) {
        source.value = [280, 30, 1000, 99.99];
    }
});
const bgList = reactive([{
  title: "一站式接入",
  sub: ["<span class='circle'>&nbsp;</span><span class='normal'>全功能</span><span class='tip'>控制台</span>", "<span class='circle'>&nbsp;</span><span class='normal'>强大丰富的</span><span class='tip'>API</span>"],
  dec: "基于功能丰富的控制台和云 API 接口，实现数行代码、一站式接入。支持对边缘模块和实例的管理、操作，并拥有丰富的运营体系和爆款应用服务经验。",
  bgImg: "Group139.webp",
  abgImg: "Group139_1.webp"
}, {
  title: "超低时延",
  sub: ["<span class='circle'>&nbsp;</span><span class='tip'>&lt25ms</span>超低延时", "<span class='circle'>&nbsp;</span><span class='tip'>5000万+</span>终端用户数/日"],
  dec: "通过广泛覆盖的靠近用户的边缘计算节点，就近为用户提供低网络时延、大带宽的智能算力服务，为时延敏感业务提供更优质的用户体验",
  bgImg: "Group_137.webp",
  abgImg: "Group137_1.webp"
}, {
  title: "边缘云原生",
  sub: ["<span class='circle'>&nbsp;</span><span class='tip'>8000+</span>边缘任务", "<span class='circle'>&nbsp;</span><span class='tip'>100000+</span>任务实例"],
  dec: "针对多碎片、广分布、高异构的边缘节点，使用自研云原生技术路径。可实现统一部署分布式应用，提升资源利用率、分布式系统的弹性扩展和可靠性。",
  bgImg: "Group_138.webp",
  abgImg: "Group_138_1.webp"
}])
</script>

<style lang="less">
.sub-title {
    font-size: 18px;
    padding-left: 20px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    margin-top: 20px;
    .circle {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #fff;
      margin-right: 5px;
    }
    .tip {
      color: #00EEC2;
    }
    &.show {
      color: #3A466A;
      padding-left: 5px;
      .circle {
        background: #3A466A;
      }
      .tip {
        color: #3A466A;
      }
    }
  }
</style>
<style lang="less" scoped>

.part-server-container {
  padding: 30px 0px 60px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  .title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    text-align: center;
    color: #fff;
    &.show {
      color: #0540FA;
      text-align: left;
    }
  }
  .desc {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #969EBA;
    line-height: 32px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding: 36px 0px 0px 0px;
  }
}
.show-large-content {
  width: 570px;
  height: 526px;
  background: linear-gradient( 180deg, #EAEFFF 0%, #F8FAFF 100%);
  border-radius: 8px;
  border: 1px solid #EAEFFF;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px bottom;
  padding: 46px 42px;
  box-sizing: border-box;
  animation: highlight-animation 0.4s 1;
  animation-timing-function: ease-in-out;
}
@keyframes highlight-animation {
  0% {
    opacity: 0;
    transform: translate(-80px);
  }
  100% {
    opacity: 1;
    transform: translate(0px);
  }
}
.show-large-content:nth-child(2n){
  margin: 0px 30px;
}
.show-content {
  width: 270px;
  height: 480px;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 46px 0px;
  box-sizing: border-box;
}
.show-content:nth-child(2n){
  margin: 0px 30px;
}


.china {
  height: 580px;
  background: linear-gradient(180deg, #0540fa 0%, #0567fa 100%);
  border-radius: 0px 0px 0px 0px;
  margin: 0 auto;
  position: relative;
}
.china-one {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  margin-top: 50px;
  font-size: 32px;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.china-two {
  font-family: DingTalk Sans, DingTalk Sans;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin-top: 20px;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.china-tree {
  font-family: PingFang SC, PingFang SC;
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  opacity: 0.6;
}
.china-flex {
  width: 100%;
  margin-top: 230px;
  display: flex;
  justify-content: space-around;
}
.china-cont {
}
.data-icon {
  font-family: DIN Alternate, DIN Alternate;
  font-weight: bold;
  font-size: 52px;
  color: #00eec2;
  line-height: 52px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.china-bom {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  margin-top: 12px;
  text-transform: none;
}

</style>
