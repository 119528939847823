<template>
    <div>
        <!-- 头部 -->
        <div class="top-container">
            <div class="about-top" :style="{height: getContainerHeight(238)}">
                <div class="about-top-text">
                    <div class="atone">一站式智能边缘云服务商</div>
                    <div class="attwo">浮点智算成立于2021年，为企业客户提供低时延、高性能、低成本、灵活可靠的一站式智能化边缘云服务和解决方案，专注云游戏、AI大模型定制化及内容制作、云电竞、云手机、云办公等沉浸式数字场景。</div>
                </div>
                <img :src="require('@/assets/about.png')"/>
            </div>
        </div>
        <!-- 广告 -->
        <div class="banner-container" :style="{height: getContainerHeight(405)}">
            <div class="banner">
                <div class="banner-content">
                    <div style="display: flex;">
                        <div class="banner-text">领先的市场地位</div>
                        <span class="banner-span">LEADING MARKET POSITION</span>
                    </div>
                    <div class="banner-two">依托在实时云渲染、云服务、底层系统软硬件、智能化边缘云等维度积累的技术专利、软件著作权和行业标准认证，浮点智算构建了围绕沉浸式数字场景的全面技术体系。目前，浮点智算服务的客户已覆盖国内主流的游戏厂商、互联网平台和更多领域，并持续扩大合作规模，具有领先的市场地位。</div>
                </div>
            </div>
        </div>
        
        <div class="custom-content">
            <div class="app-content-us">
                <div>广泛覆盖客户群</div>
                <div class="app-us-subtitle font">BROAD COVERAGE OF CUSTOMER BASE</div>
            </div>
            <div class="out-line">
                <span class="tip">游戏平台</span>
                <span class="tip left-bottom">更多合作伙伴</span>
                <div class="middle-line">
                    <span class="tip top-center">互联网平台</span>
                    <span class="tip left">电信运营商</span>
                    <span class="tip right-bottom">云公司</span>
                    <div class="center-line">
                        <span>业务范围</span>
                    </div>
                </div>
            </div>
            <div class="app-content-us">
                <div>发展里程碑</div>
                <div class="app-us-subtitle font">DEVELOPMENT MILESTONES</div>
            </div>
        </div>
        <!-- 里程碑 -->
        <div class="milestone" :style="{height: getContainerHeight(422)}">
            <!--  -->
            <div class="milestone-center">
                <div class="milestone-left">
                    <div class="milestone-left-l">
                        <div class="milestone-left-l-one">2021年 浮点智算成立</div>
                        <div class="milestone-left-l-two">基于在实时云渲染、云服务、底层系统软硬件、智能化边缘云等维度积累的丰富技术，浮点智算利用差异化的技术优势快速打入市场，成立当年即与包括国内运营商、头部云厂商、主流互联网平台和游戏厂商等诸多领域展开合作。</div>
                    </div>
                    <div class="milestone-right-con">
                        <div class="milestone-right-one"></div>
                        <div class="milestone-right-two"></div>
                    </div>
                </div>
                <!--  -->
                <div class="milestone-left milestone-right milestone-content">
                    <div class="milestone-left-l milestone-right-l milestone-content">
                        <div class="milestone-left-l-one">2023年 智能边缘云市场取得领先市场地位</div>
                        <div class="milestone-left-l-two">浮点智算服务的领域涵盖游戏、互联网平台、电信运营商、教育等众多行业，在智能边缘云市场取得领先市场地位。</div>
                    </div>
                    <div class="milestone-right-con milestone-right-cont">
                        <div class="milestone-right-one"></div>
                        <div class="milestone-right-two"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="foot-bottom">
            <h3 style="color:white">联系我们</h3>
            <h5 class="font" style="opacity: 0.5;color: white;">CONTACTUS</h5>
            <div style="display: flex; align-items: center; justify-content: flex-start; margin: auto; width: 270px; margin-top: 20px;">
                <img :src="require('@/assets/mail.png')"/>
                <span class="font" style="color: #fff; font-size: 16px; margin-left: 10px;">BUSINESS@FDCOMPUTE.COM</span>
            </div>
        </div>
    </div>
</template>
<script setup>
const getContainerHeight = (height) => {
    const realWidth = window.screen.availWidth
    return ((height / 1440) * realWidth).toFixed(0) + 'px'
}

</script>

<style scoped lang="less">
.top-container {
    background: linear-gradient( 180deg, #0540FA 0%, #0567FA 100%);
    padding-top: 120px;
    .about-top{
        width: 1170px;
        margin: auto;
        position: relative;
        img {
            position: absolute;
            width: 310px;
            bottom: 25px;
            right: 0;
        }
    }
}

.about-top-text{
    width: 470px;
}
.atone{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 42px;
    color: #FFFFFF;
    line-height: 49px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
.attwo{
    margin-top: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 26px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    opacity: 0.6;
}
.banner-box {
    background-color: #e3eafb;
}

.banner-container {
    width: 100%;
    padding-top: 60px;
    background-color: #e3eafb;
    background: url('@/assets/科幻背景.png') right bottom no-repeat;
}
.banner{
   width: 1170px;
   height: 342px;
   margin: auto;
   position: relative;
   .banner-content{
        width: 560px;
    }
    .banner-text{
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 24px;
        color: #3A466A;
        line-height: 28px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-right: 10px;
    }
    .banner-span{
        font-family: DingTalk Sans, DingTalk Sans;
        font-weight: 400;
        font-size: 16px;
        color: #3A466A;
        line-height: 28px;
        font-style: normal;
        text-transform: none;
        opacity: 0.6;
    }
    .banner-two{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #969EBA;
        line-height: 24px;
        font-style: normal;
        text-transform: none;
        margin-top: 60px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #FFFFFF;
        padding: 20px;
    }
}

.custom-content {
    background: #fff;
    .out-line {
        position: relative;
        width: 575px;
        height: 575px;
        margin: 0px auto 0px;
        background: #F7F9FF;
        border-radius: 100%;
        border: 1px solid #0540FA;
        .middle-line {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 362px;
            height: 362px;
            margin: auto;
            background: #EFF3FF;
            border-radius: 100%;
            border: 1px solid #0540FA;
            display: flex;
            justify-content: center;
            align-items: center;
            .center-line {
                width: 151px;
                height: 151px;
                background: #0540FA;
                border-radius: 100%;
                font-weight: 500;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 32px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .tip {
        top: 100px;
        right: 0;
        position: absolute;
        font-weight: 500;
        font-size: 18px;
        color: #3A466A;
        line-height: 32px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        z-index: 99;
        &::after{
            content: '';
            width: 24px;
            height: 24px;
            border-radius: 100%;
            background: blue;
            position: absolute;
            top: 46px;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            z-index: 1;
        }
        &.left-bottom {
            bottom: 135px;
            top: auto;
            right: auto;
            left: 0;
        }
        &.top-center {
            top: -45px;
            left: 0px;
            right: 0;
            margin: auto;
        }
        &.left {
            left: -42px;
            right: auto;
            top: 100px;
        }
        &.right-bottom {
            top: auto;
            bottom: 100px;
            right: 0;
        }
    }
}


.milestone{
    /* width: 1440px; */
    /* height: 422px; */
    padding-top: 171px;
    display: flex;
    background: url('../assets/里程碑背景.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
.milestone-center {
    width: 1160px;
    margin: 0px auto;
    position: relative;
}
.milestone-left{
    width: 530px;
    height: 157px;
    /* margin-left: 135px; */
    display: flex;
}
.milestone-left.milestone-right{
    flex-direction: row-reverse;
    position: absolute;
    left: 600px;
    top: 0;
}
.milestone-left.milestone-right.milestone-content{
    height: 117px;
}
.milestone-left-l.milestone-right-l.milestone-content{
    height: 117px;
    margin-top: -30px;
}
.milestone-left-l{
    width: 500px;
    height: 157px;
    padding: 12px 20px 13px 20px;
    background: rgba(255,255,255,0.5);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #FFFFFF;
}
.milestone-left-l.milestone-right-l{
    margin-left:0;
}
.milestone-right-con{
    width: 40px;
    padding-top: 147px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.milestone-right-con.milestone-right-cont{
    flex-direction: row-reverse;
    padding-top: 40px;
}
.milestone-right-one{
    width: 22px;
    height: 2px;
    background: rgba(255,255,255,0.5);
}
.milestone-right-two{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: white;
}
.milestone-left-l-one{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #2d2d30;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
.milestone-left-l-two{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #2d2d30;
    line-height: 24px;
    text-align: justified;
    font-style: normal;
    text-transform: none;
    opacity: 0.6;
}
.foot-bottom{
    height: 160px;
    background: #0540FA;
    text-align: center;
    padding-top: 30px;
}

</style>
