import { API_BASE_URL } from '@/config'
import axios from 'axios'


const service = axios.create({
  baseURL: API_BASE_URL,
  method: 'POST',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', Accept: '*/*' },
  timeout: 50000
})
service.interceptors.request.use(
  config => {
    console.log(config, 'config')
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  async res => {
    const { status, config, data } = res
    if (status == '200') {
      return data
    }
    return res
  },
  error => {
    // 抛出错误
    throw Error(error)
    //return Promise.reject(error)
  }
)

export default service
