<template>
<div class="top-container">
    <div class="about-top">
        <div class="atone">{{ attrs?.data.title }}</div>
        <div class="about-top-text">
            <div class="attwo" v-html="attrs?.data.desc"></div>
            <div class="connection-btn" @click="goPage()">{{ `${!attrs?.data?.isBanner?'立即接入':'查看详情'}` }}</div>
        </div>
        <img v-if="attrs?.data?.isBanner" class="main-icon-banner" :src="require('@/assets/' + attrs?.data.iconUrl)"/>
        <img v-if="!attrs?.data?.isBanner" class="main-icon" :src="require('@/assets/' + attrs?.data.iconUrl)"/>
        <img v-if="!attrs?.data?.isBanner" class="cover-icon" :src="require('@/assets/solution/前景覆层.png')"/>
    </div>
    <UserInfoModal ref="UserInfoModalRef"/>
</div>
</template>
<script setup>
import { useAttrs, ref } from 'vue';
import UserInfoModal from './UserInfoModal.vue';

const UserInfoModalRef = ref()
const attrs = useAttrs()
const goPage = () => {
    if(!attrs?.data?.isBanner) {
        UserInfoModalRef.value.showModal()
    } else {
        attrs.handle && attrs.handle(attrs?.data)
    }
}
</script>
<style scoped lang="less">
 .connection-btn {
    position: absolute;
    bottom: 40px;
}
.top-container {
    background: linear-gradient( 180deg, #0540FA 0%, #0567FA 100%);
    padding-top: 120px;
    .about-top{
        position: relative;
        height: 240px;
        // height: 310px;
        width: 1170px;
        margin: auto;
        position: relative;
        .main-icon {
            position: absolute;
            width: 320px;
            height: 249px;
            bottom: 20px;
            right: 0;
        }
        .main-icon-banner {
            position: absolute;
            width: 471px;
            height: 266px;
            bottom: 0px;
            right: 0;
        }
        .cover-icon {
            position: absolute;
            width: 486px;
            height: 216px;
            bottom: -35px;
            right: 0;
        }
    }
    .atone{
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 42px;
        color: #FFFFFF;
        line-height: 49px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .about-top-text {
        width: 470px;
        .attwo{
            margin-top: 12px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 26px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            opacity: 0.6;
        }
    }
}
</style>