<template>
   <div class="news">
      <div class="news-left">
        <div class="news-left-title">企业动态</div>
        <div class="news-left-ftit">ENTREPRISE'S NEWS</div>
        <button class="news-btn" @click="router.push('/news')">查看更多</button>
      </div>
      <div class="news-right">
        <div class="news-right-content" v-for="(item,index) in newsList" :key="index" @click="goPage(item.title+item.id)">
          <div class="news-time">
            <div class="news-time-one">{{item.year}}</div>
            <div class="news-time-two">{{item.month}}</div>
          </div>
          <div style="margin-top:20px;width:424px">
            <div class="news-cont-title sle">{{item.title}}</div>
            <div class="news-cont-ftit">{{item.content}}</div>
          </div>
          <div style="margin:20px;">
            <img style="width:180px;height:140px; border-radius: 8px;" :src="item.imgUrl ? item.imgUrl : require('@/assets/' + item.defaultUrl)"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'PartFive',
};
</script>
<script setup>
import { getNewsList } from '@/apis/api';
import { IMAGES_BASE_URL } from '@/config';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router'

const goPage = (id) => {
  sessionStorage.setItem('NEWSID', id)
  router.push('/news')
}
const router = useRouter()
const newsList = ref([])
onMounted(()=>{
    getNewsList().then((res)=>{
      const list = []
      if(res.code == 0) {
        res?.page?.list?.forEach(element => {
          const time = element?.createTime || '';
          const year = time.split('-')[0]
          let splitStr = time.split(' ')[0]
          let str = splitStr.split('-')
          str.shift()
          const month = str?.join('-') || '';
          let imgUrl = element.img.replace('/usr/local/tomcat', '')
          imgUrl = IMAGES_BASE_URL + imgUrl;
          const obj = Object.assign({}, {year, month, imgUrl, defaultUrl: 'news/news.jpg'}, element)
          list.push(obj)
        });
        newsList.value = list.slice(0,3)
      }
    })
})
</script>

<style lang="less" scoped>
/*  */
.news {
  justify-content: flex-start;
  display: flex;
  width: 1170px;
  margin: 0px auto 60px;
}
.news-left {
  width: 370px;
  margin-top: 88px;
  height: 572px;
  background: url("../assets/新闻背景.webp") no-repeat;
  background-size: cover;
  border-radius: 8px;
}
.news-left-title {
  padding: 40px 0 0 30px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36px;
  color: #ffffff;
  line-height: 36px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.news-left-ftit {
  padding: 12px 0 0 30px;
  font-family: DingTalk Sans, DingTalk Sans;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  opacity: 0.6;
}
.news-btn {
  width: 162px;
  height: 52px;
  margin-top: 374px;
  margin-left: 104px;
  text-align: center;
  background: rgba(5, 64, 250, 0.7);
  border-radius: 4px 4px 4px 4px;
  color: #fff;
  &:active{
      box-shadow: 2px 2px 1px 0 rgba(255, 255, 255, 0.7);
  }
}
.news-right {
  flex: 1;
  margin-top: 88px;
  margin-left: 30px;
  cursor: pointer;
}
.news-right-content {
  display: flex;
  margin-bottom: 15px;
  height: 180px;
  border-radius: 8px 8px 8px 8px;
  &:hover {
    background: #f4f7ff;
  }
}
.news-time {
  width: 100px;
  margin: 20px;
}
.news-time-one {
  transform: skew(-5deg, 0deg);
  font-family: DingTalk Sans, DingTalk Sans;
  font-weight: 400;
  font-size: 32px;
  color: #3a466a;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.news-time-two {
  font-family: DingTalk Sans, DingTalk Sans;
  font-weight: 400;
  font-size: 16px;
  color: #969eba;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.news-cont-title {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 20px;
  color: #3a466a;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.news-cont-ftit {
  height: 100px;
  margin-top: 11px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #969eba;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* 显示的行数，可以根据需要修改 */
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
