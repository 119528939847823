<template>
    <div>
        <div class="home-content">
            <div class="content-bg" :style="{height: height}">
                <div class="bg-center">
                    <img class="slog" :src="require(('@/assets/文案.svg'))">
                    <div class="content-box"></div>
                    <div class="content-illustration">
                        <img :src="require('@/assets/前景覆层.svg')" style="width: 723px; height: 388px;" />
                    </div>
                </div>
            </div>
            <div class="content-us">
                <div>一站式接入——为极致体验而生</div>
                <div class="us-subtitle font">ULTIMATE EXPERIENCE</div>
            </div>
            <PartTwo/>
            <div class="content-us">
                <div>强大的智能边缘云服务</div>
                <div class="us-subtitle font">POWERFUL INTELLIGENCE</div>
            </div>
            <PartThree/>
            <div style="background: #F4F7FF;">
                <div class="content-us">
                    <div>领先的技术</div>
                    <div class="us-subtitle font">ADVANCED TECHNOLOGY</div>
                </div>
                <PartFour/>
            </div>
            <PartFive/>
            <BottomBar />
        </div>
    </div>
</template>

<script>
export default {
    components: { PartTwo, PartThree, PartFive, BottomBar },
    name: 'HelloWorld',
    data() {
        return {};
    },
};
</script>
<script setup>
import PartTwo from '@/components/PartTwo.vue';
import PartThree from '@/components/PartThree.vue';
import PartFour from '@/components/PartFour.vue';
import PartFive from '@/components/PartFive.vue';
import BottomBar from '@/components/BottomBar.vue';
import { onMounted, onUnmounted, ref } from 'vue';

const getContainerHeight = (height) => {
    const realWidth = window.innerWidth
    return ((height / 1440) * realWidth).toFixed(0) + 'px'
}
const height = ref(getContainerHeight(740))
const calcHeight = () => {
    height.value = getContainerHeight(740)
}
onMounted(()=>{
    window.window.addEventListener('resize', calcHeight)
})
onUnmounted(()=>{
    window.window.removeEventListener('resize', calcHeight)
})
</script>

<style lang="less" scoped>
@keyframes trans {
    0% {
      transform: translateX(0) translateY(0);
    }
    50% {
      transform: translateX(15px) translateY(10px);
    }
    100% {
      transform: translateX(10px) translateY(0);
    }
}
.home-content {
    .content-bg {
        height: 740px;
        position: relative;
        overflow: hidden;
        isolation: isolate;
        background: url('@/assets/A1.png') left top no-repeat,
            url('@/assets/A2.png') center top no-repeat, url('@/assets/A3.png') top right no-repeat,
            url('@/assets/A4.png') left bottom no-repeat,
            url('@/assets/A5.png') center bottom no-repeat, url('@/assets/A6.png') bottom right no-repeat;
        background-image: url('@/assets/背景层.webp');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2650f5;
    }
    .bg-center {
        width: 1170px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin: 0px auto;
        .slog {
            left: 0;
            position: absolute;
            top: 40px;
            width: 516px;
            height: 122px;
        }
    }
    .content-box {
        width: 564px;
    }
    .content-illustration {
        // transition: all 0.1s ease;
        z-index: 1;
        animation: trans 2s infinite;
        animation-timing-function: ease-in-out;
        animation-direction: alternate;
    }
    
    .content-us {
        display: flex;
        padding-top: 60px;
        padding-bottom: 20px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        color: #1d2129;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
    }
    .us-subtitle {
        color: #666;
        font-size: 16px;
        font-style: normal;
        font-weight: 345;
        line-height: normal;
    }

}
</style>
