<template>
    <div class="performance-box">
        <img :src="require('@/assets/solution/背景装饰.png')"/>
        <div class="performance-position">
            <div class="performance">
                <div class="text-box">{{ attrs?.arr[0] }}</div>
                <div class="text-box">{{ attrs?.arr[1] }}</div>
            </div>
            <div class="performance">
                <div class="text-box">{{ attrs?.arr[2] }}</div>
                <div class="text-box">{{ attrs?.arr[3] }}</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useAttrs } from 'vue';

const attrs = useAttrs()

</script>
<style scoped lang="less">
.performance-box{
    position: relative;
    img{
        display: block;
        width: 1065px;
        height: 350px;
        margin: auto;
    }
    .performance-position {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding-top: 30px;
    }
    .performance {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-child{
            margin-top: 129px;
        }
        .text-box {
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            color: #3A466A;
            width: 400px;
            height: 88px;
            line-height: 88px;
            background: rgba(255,255,255,0.5);
            border: 1px solid #AEB8D8;
            border-radius: 8px;
        }
    }
}
</style>