<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import UserInfoModal from './UserInfoModal.vue';

const UserInfoModalRef = ref()
const menuList = reactive([{name: '解决方案', value: 'solution', path: '/solution'},{name: '产品服务', value: 'service', path: '/service'},{name: '企业动态', value: 'news', path: '/news'},{name: '关于我们', value: 'about', path: '/about'}])
const subList = reactive([{name: '云游戏', value: 'game', path: '/solution'}, {name: '云电脑', value: 'computer', path: '/computer'},{name: 'AIGC', value: 'aigc', path: '/aigc'}])
const activeIndex = ref('index')
const hoverValue = ref('')
const isFixed = ref(false)
const onMenu = (index) => {
  activeIndex.value = index
  hoverValue.value = ''
  sessionStorage.setItem('MenuIndex', index)
}
const handleScroll = () => {
  isFixed.value = window.scrollY > 60
}
const onQuery = () => {
  UserInfoModalRef.value.showModal()
}
onMounted(()=>{
  const curIndex = sessionStorage.getItem('MenuIndex')
  activeIndex.value = curIndex || 'index';
  window.addEventListener('scroll', handleScroll);
})
onUnmounted(()=>{
  window.removeEventListener('scroll', handleScroll);
})
</script>

<template>
 <div class="menu-container" :class="{fixed: isFixed}">
  <UserInfoModal ref="UserInfoModalRef"/>
  <div class="flex-center">
    <div class="flex-betwwen main">
      <div class="flx-start">
        <router-link class="logo" to='/' @click="onMenu('index')">
          <img src="@/assets/logo.svg" style="width: 120px;">
        </router-link>
        <div class="menu-center">
          <router-link class="menu-content" 
            @click="onMenu(menu.value)" 
            @mousemove="hoverValue = menu.value" 
            :class="{active: activeIndex == menu.value || ['game','computer','aigc'].includes(activeIndex) && menu.value == 'solution'}" 
            v-for="menu in menuList" :key="menu.value" :to="menu.path">{{ menu.name }}</router-link>
          <div class="sub-menu" v-if="hoverValue == 'solution'" @mouseleave="hoverValue = ''">
            <router-link class="sub-menu-content"  @click="onMenu(menu.value)" :class="{active: activeIndex == menu.value}" v-for="menu in subList" :key="menu.value" :to="menu.path">{{ menu.name }}</router-link>
          </div>
        </div>
      </div>
      <el-space class="btn-box">
        <!-- <div class="btn-self" @click="onQuery()">咨询</div> -->
        <div class="btn-self" @click="onQuery()">咨询</div>
        <!-- <div class="btn-self active" @click="onQuery()">预约</div> -->
      </el-space>
    </div>
  </div>
</div>
</template>

<style scoped lang="less">
.menu-container {
  width: 100%;
  position: relative;
  background: transparent;
  z-index: 999;
  position: fixed;
  &.fixed {
    background: #0540FA;
    animation: munuFrames .8s 1;
    animation-timing-function: ease-in;
  }
}
@keyframes munuFrames {
  0% {
    background: transparent;
  }
  50% {
    background: #0e45fb;
  }
  100% {
    background: #0540FA;
  }
}
.menu-container .logo {
  margin-right: 8px;
}
.menu-container .main {
  width: 1170px;
  margin: auto;
}
.menu-center .menu-content {
  position: relative;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  padding: 26px 0px;
  margin: 0px 21px;
  display: inline-block;
  border-bottom: 2px solid transparent;
  &.active {
    border-bottom: 2px solid #fff;
  }
  &:hover {
    border-bottom: 2px solid #fff;
  }
}
.sub-menu {
  position: absolute;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 98px;
  text-align: center;
  border-radius: 0px 0px 4px 4px;
  margin-top: -2px;
  .sub-menu-content {
    cursor: pointer;
    padding: 8px 0px;
    &.active {
      color: #fff;
      background: #0540FA;
    }
    &:hover {
      color: #fff;
      background: #0540FA;
    }
  }
}
.btn-box .btn-self {
  cursor: pointer;
  box-sizing: border-box;
  padding: 6px 21px;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 4px;
  &:active{
      box-shadow: 2px 2px 1px 0 rgba(255, 255, 255, 0.7);
  }
}
.btn-box .btn-self.active {
  background: #fff;
  color: #0567FA;
}
</style>
