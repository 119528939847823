<template>
    <div class="news-detail-container">
        <div class="back-box" @click="router.go(-1)"><img :src="require('@/assets/back.png')"/><span>返回</span></div>
        <div class="news-box">
            <div class="title-box">
                <div class="left">
                    <div class="title">{{ detail.title }}</div>
                    <div><span class="tag">{{ detail?.createTime?.split(' ')[0] || '' }}</span><span class="tag">责任编辑：{{ detail.autor }}</span></div>
                </div>
                <img :src="detail.imgUrl ? detail.imgUrl : require('@/assets'+detail.iconUrl)"/>
            </div>
            <div class="content">
                {{ detail.content }}
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter()
const detail = ref(JSON.parse(localStorage.getItem('RECORD') || '{}'))
</script>

<style scoped lang="less">
.news-detail-container {
    background: #fff;
    min-height: calc(100vh - 217px);
    .back-box {
        top: 0;   
        left: 0;
        right: 0;     
        position: fixed;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 400;
        font-size: 20px;
        color: #000000;
        line-height: 28px;
        width: 1170px;
        margin: auto;
        padding: 40px 0px 26px;
        img {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }
    }
    .news-box {
        padding-top: 106px;
        width: 830px;
        margin: auto;
        .title-box {
            display: flex;
            .title {
                font-weight: 500;
                font-size: 20px;
                color: #3A466A;
                line-height: 32px;
            }
            .tag {
                font-weight: 400;
                font-size: 16px;
                color: #969EBA;
                line-height: 16px;
                margin-right: 15px;
            }
            .left {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 10px 10px 12px 0px;
            }
            img {
                flex-shrink: 0;
                width: 230px;
                height: 178px;
                border-radius: 8px;
            }
        }
        .content {
            font-weight: 400;
            font-size: 16px;
            color: #969EBA;
            line-height: 30px;
            padding: 30px 0px 60px;
            text-indent: 36px;
        }
    }
}
</style>
