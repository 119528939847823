<template>
    <el-form ref="userInfoRef" :model="userInfo" :rules="rules" class="demo-form-inline" :hide-required-asterisk="true">
        <el-row :gutter="120" style="width: 1170px; margin: 22px auto 0px;">
            <el-col :span="12">
                <el-form-item label="姓名" size="large" prop="name">
                    <el-input v-model="userInfo.name" placeholder="请输入您的姓名" clearable />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="电话" size="large" prop="phone">
                    <el-input v-model="userInfo.phone" placeholder="请输入您的手机号" clearable />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="120" style="width: 1170px;margin: 22px auto 0px;">
            <el-col :span="12">
                <el-form-item label="公司" size="large" prop="company">
                    <el-input v-model="userInfo.company" placeholder="请输入您的公司名称" clearable />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="邮箱" size="large" prop="email">
                    <el-input v-model="userInfo.email" placeholder="请输入您的电子邮箱" clearable />
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item>
            <el-button size="large" type="primary" class="theme-button" @click="submitForm(userInfoRef)">预约专属方案</el-button>
        </el-form-item>
    </el-form>
</template>
<script setup>
import { saveCustomInfo } from '@/apis/api';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

const userInfoRef = ref()
const userInfo = ref({
    name: '',
    phone: '',
    company: '',
    email: ''
})
const rules = ref({
    name: [
        {required: true, message: '姓名不能为空'},
        {
            type: 'string',
            message: '请输入正确的姓名',
            trigger: ['blur', 'change'],
        },
    ],
    phone: [
        {required: true, message: '手机号不能为空'},
        {
            pattern: /^1[3-9]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: ['blur', 'change'],
        },
    ],
    email: [
        {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
        },
    ]
})
const submitForm = async (userInfoRef) => {
    if (!userInfoRef) return
    await userInfoRef.validate((valid) => {
        if (valid) {
            saveCustomInfo(userInfo.value).then((res)=>{
                if(res.code == 0) {
                    userInfoRef.resetFields()
                    ElMessage({message: '预约成功，客户经理会第一时间与您联系！', type: 'success'})
                }else{
                    ElMessage({message: '太火爆了，稍后再试', type: 'warning'})
                }
            })
        } 
    })
}
</script>
<style scoped lang="less">
:deep(.el-form-item__label){
    color: #fff;
}
.padding-120{
    padding: 0px 120px;
}
.margin-22 {
    margin-top: 22px;
}
.theme-button {
    background-color: #0540FA;
    color: #fff;
    text-align: center;
    border-color: #0540FA;
    margin: 20px auto 0px;
    &:active{
        box-shadow: 2px 2px 1px 0 rgba(255, 255, 255, 0.7);
    }
}
</style>