<template>
    <Menu v-if="isShow"></Menu>
    <router-view></router-view>
    <FooterPart/>
</template>

<script setup>
import Menu from './components/Menu.vue';
import FooterPart from './components/FooterPart.vue';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
const router = useRouter()
const isShow = computed(()=>{
    return router.currentRoute.value.path!='/detail'
})
console.log(router, 'router')
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
div {
    box-sizing: border-box;
}
@media screen and (max-width: 1200px) {
    #app {
        width: 1200px;
    }
}
.app-content-us {
    padding-top: 120px;
    padding-bottom: 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #3A466A;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    text-transform: none;
}
.app-us-subtitle {
    font-family: DingTalk Sans, DingTalk Sans;
    font-weight: 400;
    font-size: 16px;
    color: #3A466A;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin-top: 4px;
}
.connection-btn {
    margin-top: 54px;
    box-sizing: border-box;
    width: 96px;
    cursor: pointer;
    padding: 6px 0px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 16px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FFFFFF;
    &:active{
        box-shadow: 3px 3px 2px 0 rgba(255, 255, 255, 0.7);
    }
    &.blue {
        background: #0540FA;
        color: #FFFFFF;
        width: 206px;
        padding: 16px;
        font-size: 16px;
        margin: 0px auto 0px;
    }
}
</style>
