<template>
    <div class="flx-box">
        <div class="feature-box" v-for="item in attrs.featureContentList" :key="item.title">
            <img :src="require('@/assets/' + item.showUrl)">
            <div>
                <p class="title-self">{{ item.title }}</p>
                <div class="content">{{ item.content }}</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useAttrs } from 'vue';

const attrs = useAttrs()

</script>
<style scoped lang="less">
.flx-box{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -30px;
    .feature-box {
        flex-shrink: 0;
        width: 370px;
        height: 124px;
        background: #F0F4FF;
        border-radius: 8px 8px 8px 8px;
        padding: 20px;
        display: flex;
        justify-content: flex-start;
        margin-right: 30px;
        margin-bottom: 30px;
        &:nth-child(3n){
            margin-right: 0px;
        }
        img {
            width: 84px;
            height: 84px;
            margin-right: 20px;
        }
        p.title-self{
            font-weight: 500;
            font-size: 18px;
            color: #3A466A;
            line-height: 32px;
        }
        .content {
            margin-top: 4px;
            font-weight: 400;
            font-size: 14px;
            color: #969EBA;
            line-height: 24px;
        }
    }
}
</style>