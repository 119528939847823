<template>
    <div>
        <el-carousel :interval="3000" height="360px" autoplay>
            <el-carousel-item v-for="item in feedList" :key="item.id">
                <BannerColorBg :data="item" :handle="goDetail"/>
            </el-carousel-item>
        </el-carousel>
        <div class="news-container">
            <div class="main-container">
                <div class="table-tab">
                    <div class="tab">新闻动态</div>
                </div>
                <div class="news-content">
                    <div class="news-content-box" v-for="item in newList" :key="item.year">
                        <div class="year-month-box">
                            <p class="year">{{ item.year }}</p>
                            <p class="month">{{ item.month }}</p>
                        </div>
                        <div class="right-box">
                           <div class="right-item-box" :id="el.title + el.id" v-for="el in item.list" :key="el.title" @click="goDetail(el)">
                                <div class="left">
                                    <p class="subTitle sle">{{ el.title }}</p>
                                    <p class="content mle3">{{ el.content }}</p>
                                </div>
                                <img style="border-radius: 4px;" :src="el.imgUrl ? el.imgUrl : require('@/assets/'+ el.defaultUrl)"/>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getNewsList } from '@/apis/api';
import BannerColorBg from '@/components/BannerColorBg.vue';
import { onMounted, ref } from 'vue';
import { IMAGES_BASE_URL } from '@/config'
import router from '@/router';

const newList = ref([{
    year: '2024',
    month: '12 月',
    list: [
        {
            title: 'A.O.史密斯率先打造高端智慧互联全新生态 为亿万家庭缔造“好风好水健康生',
            content: '第一次打破非线性诅咒的当属现代Deep Learning大牛Hinton，其在1986年发明了适用于多层感知器（MLP）的BP算法，并采用Sigmoid进行非线性映射，有效解决了非线性分类和学习的问题。该方法引起了神经网络的第二次热潮。 1989年，Robert Hecht-Nielsen证明了MLP的万能逼近定理，即对于任何闭区间内的一个连续函数f，都可以用含有一个隐含层的BP网络来逼近该定理的发现极大的鼓舞了神经网络的研究人员。 同年，LeCun发明了卷积神经网络-LeNet，并将其用于数字识别，且取得了较好的成绩，不过当时并没有引起足够的注意。 1997年，LSTM模型被发明，尽管该模型在序列建模上的特性非常突出，但由于……',
            imgUrl: 'news/news.jpg'
        },
        {
            title: '为亿万家庭缔造“好风好水健康生',
            content: '第一次打破非线性诅咒的当属现代Deep Learning大牛Hinton，其在1986年发明了适用于多层感知器（MLP）的BP算法，并采用Sigmoid进行非线性映射，有效解决了非线性分类和学习的问题。该方法引起了神经网络的第二次热潮。 1989年，Robert Hecht-Nielsen证明了MLP的万能逼近定理，即对于任何闭区间内的一个连续函数f，都可以用含有一个隐含层的BP网络来逼近该定理的发现极大的鼓舞了神经网络的研究人员。 同年，LeCun发明了卷积神经网络-LeNet，并将其用于数字识别，且取得了较好的成绩，不过当时并没有引起足够的注意。 1997年，LSTM模型被发明，尽管该模型在序列建模上的特性非常突出，但由于……',
            imgUrl: 'news/news.jpg'
        },
        {
            title: '好风好水健康生',
            content: '1989年，Robert Hecht-Nielsen证明了MLP的万能逼近定理，即对于任何闭区间内的一个连续函数f，都可以用含有一个隐含层的BP网络来逼近该定理的发现极大的鼓舞了神经网络的研究人员。 同年，LeCun发明了卷积神经网络-LeNet，并将其用于数字识别，且取得了较好的成绩，不过当时并没有引起足够的注意。 1997年，LSTM模型被发明，尽管该模型在序列建模上的特性非常突出，但由于……',
            imgUrl: 'news/news.jpg'
        },
    ]
},{
    year: '2023',
    month: '11 月',
    list: [
        {
            title: 'A.O.史密斯率先打造高端智慧互联全新生态 为亿万家庭缔造“好风好水健康生',
            content: '第一次打破非线性诅咒的当属现代Deep Learning大牛Hinton，其在1986年发明了适用于多层感知器（MLP）的BP算法，并采用Sigmoid进行非线性映射，有效解决了非线性分类和学习的问题。该方法引起了神经网络的第二次热潮。 1989年，Robert Hecht-Nielsen证明了MLP的万能逼近定理，即对于任何闭区间内的一个连续函数f，都可以用含有一个隐含层的BP网络来逼近该定理的发现极大的鼓舞了神经网络的研究人员。 同年，LeCun发明了卷积神经网络-LeNet，并将其用于数字识别，且取得了较好的成绩，不过当时并没有引起足够的注意。 1997年，LSTM模型被发明，尽管该模型在序列建模上的特性非常突出，但由于……',
            imgUrl: 'news/news.jpg'
        },
        {
            title: '为亿万家庭缔造“好风好水健康生',
            content: '第一次打破非线性诅咒的当属现代Deep Learning大牛Hinton，其在1986年发明了适用于多层感知器（MLP）的BP算法，并采用Sigmoid进行非线性映射，有效解决了非线性分类和学习的问题。该方法引起了神经网络的第二次热潮。 1989年，Robert Hecht-Nielsen证明了MLP的万能逼近定理，即对于任何闭区间内的一个连续函数f，都可以用含有一个隐含层的BP网络来逼近该定理的发现极大的鼓舞了神经网络的研究人员。 同年，LeCun发明了卷积神经网络-LeNet，并将其用于数字识别，且取得了较好的成绩，不过当时并没有引起足够的注意。 1997年，LSTM模型被发明，尽管该模型在序列建模上的特性非常突出，但由于……',
            imgUrl: 'news/news.jpg'
        },
        {
            title: '好风好水健康生',
            content: '1989年，Robert Hecht-Nielsen证明了MLP的万能逼近定理，即对于任何闭区间内的一个连续函数f，都可以用含有一个隐含层的BP网络来逼近该定理的发现极大的鼓舞了神经网络的研究人员。 同年，LeCun发明了卷积神经网络-LeNet，并将其用于数字识别，且取得了较好的成绩，不过当时并没有引起足够的注意。 1997年，LSTM模型被发明，尽管该模型在序列建模上的特性非常突出，但由于……',
            imgUrl: 'news/news.jpg'
        },
    ]
}])
const feedList = ref([])
const formatList = (data) => {
    let map = {}
    const list = []
    data?.forEach((ele)=>{
        const time = ele?.createTime || '';
        const year = time.split('-')[0]
        const month = time.split('-')[1]
        const img = ele?.img?.replace('/usr/local/tomcat', '') || ''
        
        const imgUrl = img ? IMAGES_BASE_URL + img : '';
        const defaultUrl = 'news/news.jpg'
        const obj = Object.assign({}, ele, { year, month: month + '月', imgUrl, defaultUrl, iconUrl: defaultUrl })
        if (map[year]?.length) map[year].push(obj)
        else map[year] = [obj]
        if(ele.status == 1){
            let desc = ele?.content?.slice(0,100)
            desc = desc.length == 100 ? desc + '...' : desc
            feedList.value.push(Object.assign({}, ele, {desc, iconUrl: 'news/广告图.png', imgUrl, isBanner: true}))
        } 
    })
    for (const key in map) {
        list.push({year: key, month: map[key] && map[key][0]?.month, list: map[key] }) 
    }
    return list
}

const scrollTargetPositon = (id) => {
    const targetElement = document.getElementById(id);
    window.scrollTo({
        top: targetElement?.offsetTop || 0,
        behavior: 'smooth'
    });
}

const goDetail = (record) => {
    console.log(record, 'record===')
    localStorage.setItem('RECORD', JSON.stringify(record))
    router.push('/detail')
}
onMounted(()=>{
    getNewsList().then((res)=>{
      if(res.code == 0) {
        newList.value = formatList(res?.page?.list || [])
        setTimeout(()=>{
            const id = sessionStorage.getItem('NEWSID')
            if(id) scrollTargetPositon(id)
            sessionStorage.removeItem('NEWSID')
        }, 100)
      }
    })
})
</script>
<style scoped lang="less">
.news-container {
    background: #fff;
    .main-container {
        width: 1170px;
        margin: auto;
        .table-tab {
            padding: 32px 0px 0px;
            display: flex;
            justify-content: flex-start;
            border-bottom: 1px solid #DCE5FF;
            margin-bottom: 30px;
            .tab {
                padding: 0px 0px 16px 0px;
                font-weight: 500;
                font-size: 22px;
                color: #0540FA;
                line-height: 22px;
                border-bottom: 2px solid #0540FA;
            }
        }

        .news-content-box {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .year-month-box {
                .year {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 32px;
                    color: #3A466A;
                    line-height: 28px;
                }
                .month {
                    font-weight: 500;
                    font-size: 16px;
                    color: #969EBA;
                    line-height: 28px;
                }
            }

            .right-box {
                position: relative;
                margin-left: 33px;
                padding: 0px 0px 60px 60px;
                border-left: 1px solid #3A466A;
                
                &::before {
                    z-index: 1;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -6px;
                    width: 0;
                    height: 0;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-right: 6px solid #3A466A;
                }
                .right-item-box {
                    cursor: pointer;
                    background: #F4F7FF;
                    padding: 20px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 30px;
                }
                .left {
                    .subTitle{
                        font-weight: 500;
                        font-size: 20px;
                        color: #3A466A;
                        line-height: 32px;
                    }
                    .content {
                        font-weight: 400;
                        font-size: 16px;
                        color: #969EBA;
                        line-height: 26px;
                        margin-top: 10px;
                        letter-spacing: 2px;
                        // text-indent: 38px;
                    }
                }
                img {
                    width: 280px;
                    height: 195px;
                    margin-left: 20px;
                }
            }
        }
    }
}
</style>
