<template>
    <div>
        <BannerColorBg :data="topObj"></BannerColorBg>
        <div class="white-bg-container">
            <div class="main-container">
                <p class="title">方案优势<span class="font">SOLUTION ADVANTAGES</span></p>
                <PlansAdvantage :imageContentList="imageContentList"></PlansAdvantage>
                <p class="title">方案特点<span class="font">PROGRAM FEAFTURUE</span></p>
                <PlanFeature :featureContentList="featureContentList"></PlanFeature>
                <p class="title">方案架构<span class="font">SOLUTION ARCHITECTURE</span></p>
                <el-row class="table-container" :gutter="30">
                    <el-col :span="8">
                        <div class="table-header">{{ userList.title }}</div>
                        <div class="table-body height-auto">
                            <div class="middle-box">
                                <div class="flx-center" v-for="el in userList.items" :key="el.content">
                                    <img :src="require('@/assets/solution/' + el.url)"/>
                                    <div class="text">{{ el.title }}</div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div v-for="(item, index) in centerList" :key="item.title" :style="{marginTop: index != 1 ? '0px' : '30px', marginBottom: index != 1 ? '0px' : '30px'}">
                            <div class="table-header top-color-gray">{{ item.title }}</div>
                            <div class="table-body">
                                <div class="body-content-box">
                                    <div class="box" v-for="el in item.items" :key="el.title">
                                        <img :src="require('@/assets/solution/' + el.url)"/>
                                        <div class="text">{{ el.title }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div v-for="(item, index) in rightColList" :key="item.title" :style="{marginBottom: index == 1 ? '0px' : '30px'}">
                            <div class="table-header" :class="{'top-color-green': index == 0, 'top-color-purple': index == 1}">{{ item.title }}</div>
                            <div class="table-body height-auto-col">
                                <div class="middle-box">
                                    <div class="flx-center" v-for="el in item.items" :key="el.title">
                                        <img :src="require('@/assets/solution/' + el.url)"/>
                                        <div class="text">{{ el.title }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div class="white-bg-container gray">
            <div class="main-container">
                <p class="title">方案性能<span class="font">SOLUTION PERFORMANCE</span></p>
                <PlanPerformance :arr="['自研PaaS平台', '低延迟、低卡顿率、帧率稳定', '高灵活度、高利用率', '高性能并发']"></PlanPerformance>
            </div>
        </div>
        <!-- 热门方案 -->
        <div class="white-bg-container">
            <div class="main-container">
                <p class="title">热门方案<span class="font">POPULAR PLANS</span></p>
                <PlanPoular :solutionList="solutionList"></PlanPoular>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive } from 'vue';
import BannerColorBg from '@/components/BannerColorBg.vue';
import PlansAdvantage from '@/components/PlansAdvantage.vue';
import PlanPerformance from '@/components/PlanPerformance.vue'
import PlanPoular from '@/components/PlanPopular.vue'
import PlanFeature from '@/components/PlanFeature.vue'

const topObj = reactive({
    title: '疾速构建全端云游戏能力',
    desc: '专为云游戏设计，可快速将游戏转化为云游戏，或打造云原生游戏。支持游戏自助云化、运行参数定制，提供跨终端体验的云原生游戏体验。 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
    iconUrl: 'solution/云游戏.png'
})

const imageContentList = reactive([
    {title: '即点即玩', showUrl: 'solution/Group56_1.png', bgUrl: 'solution/Group56.png', subtitle: '即点即玩', subContent: '通过云游戏，在手机无需下载、即点即玩主机级精品游戏大作为流量平台解决内容匮乏问题。'},
    {title: '多渠道入口', showUrl: 'solution/Group55_1.png', bgUrl: 'solution/Group55.png', subtitle: '多渠道入口', subContent: '游戏云化为视频，直接嵌入微信／浏览器／互联网APP中，多渠道流量入口、游戏试玩广告等，降低获客成本。'},
    {title: '打通手机渠道', showUrl: 'solution/Group54_1.png', bgUrl: 'solution/Group54.png', subtitle: '打通手机渠道', subContent: '为主机游戏厂商打通手机渠道。中国游戏PC／主机的市场约5000万台／年，但手游用户数达6.5亿。'},
    {title: '提升游戏品质', showUrl: 'solution/Group53_1.png', bgUrl: 'solution/Group53.png', subtitle: '提升游戏品质', subContent: '利用GPU云计算可以全面提升游戏品质 提供体验更好的原生态云游戏，满足高ARPU值用户对于品质的需求。'},
    {title: '游戏业务视频化', showUrl: 'solution/Group52_1.png', bgUrl: 'solution/Group52.png', subtitle: '游戏业务视频化', subContent: '为流量平台提供更多的收费模式（订阅制、试玩广告等）。'}
])
const featureContentList = reactive([
    {title: '游戏预启动', showUrl: 'solution/启动.png', content: '跳过启动、加载、动画时间直达游戏'},
    {title: '游戏自动更新', showUrl: 'solution/更新.png', content: '游戏版本自动更新，无需等待快速体验新版本'},
    {title: '本地登录/支付', showUrl: 'solution/支付.png', content: '实现本地登录、支付，还原原生游戏体验'},
    {title: '画质自动调整', showUrl: 'solution/画质.png', content: '根据实时网络情况自动调整画面质量，保障最优体验'},
    {title: '文件上传下载', showUrl: 'solution/文件.png', content: '本地-云端可实时传输图片、视频等文件'},
    {title: '异形屏适配', showUrl: 'solution/适配.png', content: '自动适配多种屏幕比例设备及挖孔屏、刘海屏等异形屏'},
    {title: '本地传感器调用', showUrl: 'solution/传感器.png', content: '云端可直接调用GPS、麦克风、摄像头等本地硬件'},
    {title: '一键开播', showUrl: 'solution/一键.png', content: '云游戏一键开启直播，打通云玩直播场景'},
    {title: '控制权转移', showUrl: 'solution/控制权.png', content: '支持游戏控制权移交，实现直播互动场景'},
])

const centerList = reactive([
    {title: '云游戏服务商', items: [
        {url: 'icon_17.png', title: '手游容器'},
        {url: 'icon_16.png', title: '手游虚拟器'},
        {url: 'icon_15.png', title: '端游容器'},
        {url: 'icon_14.png', title: '游戏直播'},
    ]},
    {title: '云游戏控制台', items: [
        {url: 'icon_13.png', title: '用户管理'},
        {url: 'icon_12.png', title: '运行监控'},
        {url: 'icon_11.png', title: 'API'},
        {url: 'icon_10.png', title: '镜像管理'},
    ]},
    {title: '云游戏公共服务', items: [
        {url: 'icon_9.png', title: '文件服务'},
        {url: 'icon_8.png', title: '调度服务'},
        {url: 'icon_7.png', title: '监控服务'},
        {url: 'icon_6.png', title: '计费管理'},
    ]},
])
const rightColList = reactive([
    {title: '后台', items: [
        {url: 'icon_1.png', title: 'A游戏后台'},
        {url: 'icon_1.png', title: 'B游戏后台'},
        {url: 'icon_1.png', title: 'C游戏后台'},
    ]},
    {title: '游戏发行', items: [
        {url: 'icon_2.png', title: '游戏工作室'},
        {url: 'icon_3.png', title: '发行'},
        {url: 'icon_4.png', title: '游戏运营平台'},
    ]}
])
const userList = reactive({title: '用户', items: [
    {url: 'icon_5.png', title: '手机用户'},
    {url: 'icon_5.png', title: 'PC用户'},
    {url: 'icon_5.png', title: '机顶盒用户'},
    {url: 'icon_5.png', title: '直播用户'},
]})

const solutionList = reactive([
    {title: '精品云游戏大作', url: '/solution/游戏.png', 
        adv: ['无需下载即可试玩，大幅提升推广效率', '突破传统的单一App端营收渠道，快速增加营收可能', '扩大设备兼容性，提升用户覆盖范围','丰富的推广形式与分发渠道，降本增效'], 
        bad: ['精品游戏用户终端规格要求高,无法覆盖更多用户','现有方案体验差、效率低、成本高','推广方式单一、营销成本高昂']},
    {title: '云游戏平台', url: '/solution/平台.png', 
        adv: ['兼容X86、ARM内容生态，跨终端', '向下兼容日款设备、低规格设备，大规模提升覆盖', '丰富的推广形式与分发渠道，降本增效','统一部署、统一维护管理'], 
        bad: ['受终端限制，无法覆盖更广用户','营收模式单一、分发渠道成本高昂','部署、管理、运营效率低下']},
    {title: '云微端', url: '/solution/微端.png', 
        adv: ['创建轻量级的微端安装包，替换游戏整包进行广告投放', '降低下载时对手机终端的配置门槛', '大幅提高获客成功率、提高广告投放效率'], 
        bad: ['游戏日益重度化，包体迅速增加','投放的下载转化低']},
    {title: '游戏云发行', url: '/solution/发行.png', 
        adv: ['创新宣发形式，PC/H5链接“即点即玩”，有效利用官网、社交媒体等获取目标用户', '小体积单游戏包，支持秒速下载、提升下载与转化', '突破用户终端性能及存储空间限制，扩大游戏用户范围'], 
        bad: ['传统发行渠道昂贵、转化低','用户终端千差万别，转化受空间和性能影响极大']},
    {title: '互动游戏直播', url: '/solution/发行.png', 
        adv: ['云原生与互动玩法任意组合，秒开即玩，与原有场景和业务无感融合', '提供了千人甚至更多人同时参与的互动场景，不受本地终端限制', '简单部署即可快速运行，并能够持续更新百余款互动玩法'], 
        bad: ['直播社交增长迅猛，对新的互动玩法需求急剧增加','社交平台的互动形式更新迭代快，需要更容易部署的互动方案','直播设备千差万别，无法广泛推广通过本地运行的互动方案','弹幕互动参与人数众多，对运行的设备要求极高']},
])

</script>
<style scoped lang="less">

.white-bg-container {
    background: #fff;
    padding-bottom: 60px;
    &.gray {
        background-color: rgba(5, 64, 250, 0.08);
    }
    .main-container {
        width: 1170px;
        margin: auto;
        .title {
            font-weight: 600;
            font-size: 24px;
            color: #3A466A;
            line-height: 28px;
            padding: 40px 0px 20px 0px;
            span {
                font-size: 16px;
                color: #3A466A;
                line-height: 28px;
                padding: 8px;
            }
        }
    }
    

    .table-container {
        .table-header {
            background: #FFFFFF;
            border-radius: 0px 0px 0px 0px;
            border: 1px solid #AEB8D8;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            // font-size: 20px;
            color: #3A466A;
            line-height: 40px;
            // line-height: 64px;
            text-align: center;
            border-top: 4px solid #2F80FF;
            border-radius: 4px 4px 0px 0px;
            &.marginTop30 {
                margin-top: 30px;
            }
            &.top-color-green {
                border-top: 4px solid #00EEC2;
            }
            &.top-color-purple {
                border-top: 4px solid #7F45FF;
            }
            &.top-color-gray {
                border-top: 4px solid #969EBA;
            }
        }
        .table-body {
            background: #F0F4FF;
            border-radius: 0px 0px 4px 4px;
            border: 1px solid #AEB8D8;
            box-sizing: border-box;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            .middle-box {
                .flx-center {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 254px;
                    height: 44px;
                    // height: 67px;
                    background: #fff;
                    border-radius: 8px 8px 8px 8px;
                    border: 1px solid #AEB8D8;
                    padding: 20px 0px 20px 66px;
                    margin-top: 30px;
                    &:first-child {
                        margin-top: 0px;
                    }
                    img {
                        width: 28px;
                        height: 28px;
                        margin-right: 20px;
                    }
                    .text {
                        font-weight: 400;
                        font-size: 14px;
                        color: #3A466A;
                        line-height: 28px;
                    }
                }
            }
            .body-content-box {
                width: 340px;
                height: 100px;
                background: #fff;
                border-radius: 4px;
                border: 1px solid #B6C8FF;
                display: flex;
                justify-content: space-around;
                .box {
                    text-align: center;
                    padding-top: 23px;
                    img {
                        width: 28px;
                        height: 28px;
                    }
                    .text {
                        font-weight: 400;
                        font-size: 14px;
                        color: #3A466A;
                        line-height: 28px;
                    }
                }
            }
            
            &.height-auto {
                height: 600px;
            }
            &.height-auto-col {
                height: 263px;
            }
        }
    }
}
</style>