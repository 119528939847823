import service from '@/utils/service.js'

export const getNewsList = () => {
  return service({
    method: 'GET',
    url: "/renren-fast/order/news/list?page=1&limit=100&title"
  })
}
/**http://icloudsky.yunkannet.com/renren-fast/order/news/list?page=1&limit=100&title
 * 
 * @param {*} params 
 * @returns name, company, phone, email
 */
export const saveCustomInfo = (params) => {
  return service({
    method: 'POST',
    url: 'renren-fast/order/customer/save',
    data: params
  })
}