<template>
    <div class="plans-container">
        <div class="plan-box" v-for="item in attrs?.solutionList" :key="JSON.stringify(item.adv)">
            <div class="bg-text" :style="{background: `url(${require('@/assets'+item.url)}) no-repeat`}"><span>{{ item.title }}</span></div>
            <div class="plan-body">
                <div class="white-content">
                    <div class="bg-title"></div>
                    <div class="content-text" v-for="el in item.adv" :key="el">{{ el }}</div>
                </div>
                <div class="white-content">
                    <div class="bg-title gray"></div>
                    <div class="content-text gray" v-for="el in item.bad" :key="el">{{ el }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useAttrs } from 'vue';

const attrs = useAttrs()

</script>
<style scoped lang="less">
.plans-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .plan-box {
        width: 570px;
        margin-right: 30px;
        margin-bottom: 30px;
        &:nth-child(2n){
            margin-right: 0px;
        }
        &:last-child{
            margin-bottom: 0px;
        }
        .bg-text {
            height: 160px;
            background-color: #0540FA;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px 4px 0px 0px;
            span{
                font-weight: 600;
                font-size: 20px;
                color: #FFFFFF;
                line-height: 28px;
            }
        }
        .plan-body {
            background: rgba(240, 244, 255, 1);
            padding: 20px;
            display: flex;
            justify-content: flex-start;
            border-radius: 8px;
            .white-content {
                background: #fff;
                width: 269px;
                height: 320px;
                border-radius: 4px;
                padding: 24px 20px;
                margin-right: 8px;
                &:nth-child(2n){
                    margin-right: 0px;
                }
                .bg-title {
                    width: 72px;
                    height: 15px;
                    margin-top: 15px;
                    background: #81FFD2;
                    position: relative;
                    margin: auto;
                    &::after {
                        width: 72px;
                        text-align: center;
                        top: -15px;
                        position: absolute;
                        font-weight: 500;
                        font-size: 20px;
                        color: #3A466A;
                        line-height: 28px;
                        content: '优势';
                        
                    }
                    &.gray {
                        background: #D3D8EA;
                        &::after {
                            content: '痛点';
                        }
                    }
                }
                .content-text {
                    font-weight: 400;
                    font-size: 14px;
                    color: #3A466A;
                    line-height: 24px;
                    margin-top: 20px;
                    &::before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        border-radius: 100%;
                        background: #3A466A;
                        margin-right: 5px;
                        display: inline-block;
                        margin-bottom: 3px;
                    }
                    &.gray {
                        color: #969EBA;
                        &::before {
                            background: #969EBA;
                        }
                    }
                }
            }
        }
    }
}
</style>