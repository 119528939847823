<template>
    <div>
        <BannerColorBg :data="topObj"></BannerColorBg>
        <div class="white-bg-container">
            <div class="main-container">
                <p class="title">方案优势<span class="font">SOLUTION ADVANTAGES</span></p>
                <PlansAdvantage :imageContentList="imageContentList"></PlansAdvantage>
                <p class="title">方案特点<span class="font">PROGRAM FEAFTURUE</span></p>
                <PlanFeatureItem :featureContentList="featureContentList"></PlanFeatureItem>
                <p class="title">方案架构<span class="font">SOLUTION ARCHITECTURE</span></p>
                <div class="table-container" v-for="(item, index) in colList" :key="item.title" :style="{marginTop: index != 1 ? '0px' : '30px', marginBottom: index != 1 ? '0px' : '30px'}">
                    <div :class="`table-header ${item.class}`">{{ item.title }}</div>
                    <div class="table-body">
                        <div class="body-content-box" v-if="index === 0">
                            <div class="box" v-for="el in item.items" :key="el">{{ el }}</div>
                        </div>
                        <div class="body-content-box" v-if="index === 1">
                            <div class="child-box" v-for="el in item.items" :key="el.title">
                                <div class="child-title">{{ el.title }}</div>
                                <div class="child-body">
                                    <div class="box" v-for="child in el.arr" :key="child">{{ child }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="body-content-box" v-if="index === 2">
                            <div>
                                <div style="display: flex; justify-content: center;">
                                    <div class="child-box" v-for="el in item.item" :key="el.title">
                                        <div class="child-title">{{ item.title }}</div>
                                        <div class="child-body five">
                                            <div class="box" v-for="child in el.arr" :key="child">{{ child }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: flex-start; margin-top: 20px;">
                                    <div class="child-box" v-for="el in item.items" :key="el.title">
                                        <div class="child-title">{{ el.title }}</div>
                                        <div class="child-body">
                                            <div class="box" v-for="child in el.arr" :key="child">{{ child }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="white-bg-container gray">
            <div class="main-container">
                <p class="title">方案性能<span class="font">SOLUTION PERFORMANCE</span></p>
                <PlanPerformance :arr="['基础环境一键灵活部署', '高性能算力支持', '容量大、成本低', '易接入、易管理、易调度']"></PlanPerformance>
            </div>
        </div>
        <!-- 热门方案 -->
        <div class="white-bg-container">
            <div class="main-container">
                <p class="title">热门方案<span class="font">POPULAR PLANS</span></p>
                <PlanPoular :solutionList="solutionList"></PlanPoular>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive } from 'vue';
import PlanFeatureItem from '@/components/PlanFeatureItem.vue';
import PlanPerformance from '@/components/PlanPerformance.vue';
import BannerColorBg from '@/components/BannerColorBg.vue';
import PlansAdvantage from '@/components/PlansAdvantage.vue';
import PlanPoular from '@/components/PlanPopular.vue'

const topObj = reactive({
    title: '快捷创建定制化AI大模型',
    desc: '专为快速创建和部署模型研发，提供多规格、多样化智能算力，拥有大规模分布式训练、推理加速能力，助力千行百业数字化、智能化升级。 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
    iconUrl: 'ai/AIGC.png'
})

const imageContentList = reactive([
    {title: '快速部署', showUrl: 'ai/Group56_1.png', bgUrl: 'ai/Group56.png', subtitle: '快速部署', subContent: '部署方式简单、接入方式便捷'},
    {title: '灵活可靠', showUrl: 'ai/Group55_1.png', bgUrl: 'ai/Group55.png', subtitle: '灵活可靠', subContent: '多规格、多样化智能算力可按需调度'},
    {title: '提速增效', showUrl: 'ai/Group54_1.png', bgUrl: 'ai/Group54.png', subtitle: '提速增效', subContent: '资源协同管理，提高训练速度和效果'},
    {title: '专业服务', showUrl: 'ai/Group53_1.png', bgUrl: 'ai/Group53.png', subtitle: '专业服务', subContent: '一站式、全链路大模型搭建和服务能力'},
    {title: '安全可控', showUrl: 'ai/Group52_1.png', bgUrl: 'ai/Group52.png', subtitle: '安全可控', subContent: '强大网络安全、数据安全管控能力'},
])

const featureContentList = reactive([
    {title: '一站式服务', showUrl: 'ai/一站式.png', content: '降低技术门槛，快捷接入，提供定制化服务。'},
    {title: '超高性价比', showUrl: 'ai/性价比.png', content: '统一资源调度，超高复用率。'},
    {title: '高性能加持', showUrl: 'ai/高性能加持.png', content: '专研方案，专用技术，提供极致性能。'},
    {title: '场景多元化', showUrl: 'ai/多元化.png', content: '提供面向不同行业、场景的应用服务。'},
])

const solutionList = reactive([
    {title: '大规模训练加速', url: '/ai/solution1.png', 
        adv: ['提供高性能算力资源和计算集群，加速训练效果', '简化搭建工作，提高资源利用效率', '通过对算力、显存、内存的调度和管理，提高加速效率'], 
        bad: ['训练周期长，难以跟上业务上线进度','工程门槛高，算力、网络、模型、数据等多方资源调度难','配置调整困难，资源管理难度大']},

    {title: '大规模推理优化', url: '/ai/solution2.png', 
        adv: ['硬件选型丰富，可适配各类需求，并实现统一调度', '高性能推理优化，通过多种技术手段实现运算加速', '灵活调度，提高GPU算力和显存资源的利用率'], 
        bad: ['多样化的推理服务和GPU资源，难以统一管理', '推理服务性能低下，单机性能不足', 'GPU资源利用率低，算力资源和显存容量难以平衡']},

    {title: '内容制作', url: '/ai/solution3.png', 
        adv: ['一键生成，降低内容制作硬件门槛，提高产出质量', '内容丰富写实，涵盖不同文本和题材', '可满足不同应用场景，内容创作的高效助手'], 
        bad: ['内容制作效率低、复制力低，智能化程度不足','文本兼容性弱，效果差，产出单一','内容制作硬件设备性能不足']}
])
const colList = reactive([
    {title: '业务层', class: '',  items: ['智能标注', '分布式训练','自动化建模','自定义模版','过度可视化','IDE开发工具']},

    {title: 'AI训练及模型管理中心', class: 'top-color-green',  items: [{
        title: '模型训练',
        arr: ['交互式编程','可视化建模','训练模版管理','分布式训练','训练过程可视化','训练模版共享','训练任务调度','训练框架','手动调参','自动调参','定制训练模型','更多…']
    },
    {
        title: '模型管理与监控',
        arr: ['模型版本管理','训练模版管理','结构可视化','模型对比','结果可视化','更多…']
    },
    {
        title: '模型部署',
        arr: ['模型边缘端适配','一键部署','模型性能评估','高性能计算库','部署模型管理','更多…']
    }]},

    {title: '基础设置', class: 'top-color-purple', 
    item: [{
        title: '容器平台',
        arr: ['调度引擎','存储引擎','服务化管理','系统监测','消息服务']
    }],
    items: [{
        title: '计算资源',
        arr: ['交互式编程','可视化建模','训练模版管理']
    },
    {
        title: '储存资源',
        arr: ['交互式编程','可视化建模','训练模版管理']
    },{
        title: '网络资源',
        arr: ['交互式编程','可视化建模','训练模版管理']
    }]}
])
</script>
<style scoped lang="less">

.white-bg-container {
    background: #fff;
    padding-bottom: 60px;
    &.gray {
        background-color: rgba(5, 64, 250, 0.08);
    }
    .main-container {
        width: 1170px;
        margin: auto;
        .title {
            font-weight: 600;
            font-size: 24px;
            color: #3A466A;
            line-height: 28px;
            padding: 40px 0px 20px 0px;
            // padding: 60px 0px 30px 0px;
            span {
                font-size: 16px;
                color: #3A466A;
                line-height: 28px;
                padding: 8px;
            }
        }
    }

}

.table-container {
    .table-header {
        height: 64px;
        border-radius: 0px 0px 0px 0px;
        border: 1px solid #AEB8D8;
        color: #0540FA;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        line-height: 64px;
        padding-left: 24px;
        border-top: 4px solid #2F80FF;
        border-radius: 4px 4px 0px 0px;
        &.marginTop30 {
            margin-top: 30px;
        }
        &.top-color-green {
            border-top: 4px solid #00EEC2;
            color: #00EEC2;
        }
        &.top-color-purple {
            border-top: 4px solid #7F45FF;
            color: #7F45FF;
        }
    }
    .table-body {
        background: #F0F4FF;
        border-radius: 0px 0px 4px 4px;
        border: 1px solid #AEB8D8;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0px;
        .body-content-box {
            display: flex;
            align-items: flex-start;
            .box {
                width: 154px;
                text-align: center;
                font-weight: 400;
                font-size: 18px;
                color: #3A466A;
                line-height: 28px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #B6C8FF;
                padding: 4px 0px;
                margin-right: 20px;
                &:nth-child(6n){
                    margin-right: 0px;
                }
            }
            .child-box {
                .child-title {
                    text-align: center;
                    font-weight: 500;
                    font-size: 18px;
                    color: #000000;
                    line-height: 28px;
                }
                .child-body {
                    width: 350px;
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0px 20px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    
                    .box {
                        width: 110px;
                        padding: 2px 0px;
                        margin-right: 10px;
                        margin-top: 10px;
                        font-size: 14px;
                        &:nth-child(3n){
                            margin-right: 0px;
                        }
                    }
                    &.five {
                        width: 590px;
                        .box {
                            &:nth-child(3n){
                                margin-right: 10px;
                            }
                            &:nth-child(5n){
                                margin-right: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

</style>