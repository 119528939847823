<template>
    <div>
        <BannerColorBg :data="topObj"></BannerColorBg>
        <div class="white-bg-container">
            <div class="main-container">
                <p class="title">方案优势<span class="font">SOLUTION ADVANTAGES</span></p>
                <PlansAdvantage :imageContentList="imageContentList"></PlansAdvantage>
                <p class="title">方案特点<span class="font">PROGRAM FEAFTURUE</span></p>
                <PlanFeatureItem :featureContentList="featureContentList"></PlanFeatureItem>
                <p class="title">方案架构<span class="font">SOLUTION ARCHITECTURE</span></p>
                <div class="title-line-container">
                    <span>管理资源池</span>
                    <div class="line-with-arrow">
                        <div class="right-icon left-blue"></div>
                        <div class="right-icon right-green"></div>
                    </div>
                    <span>调度服务器</span>
                    <div class="line-with-arrow green-start">
                        <div class="right-icon left-green"></div>
                        <div class="right-icon right-purple"></div>
                    </div>
                    <span>局域网/互联网</span>
                </div>
                <el-row class="table-container" :gutter="30">
                    <el-col :span="8">
                        <div class="table-header">GPU服务器资源池</div>
                        <div class="table-body">
                            <img class="img" :src="require('@/assets/service/Group119.png')"/>
                        </div>
                        <div class="table-header marginTop30">存储资源池</div>
                        <div class="table-body">
                            <img class="img" :src="require('@/assets/service/Group120.png')"/>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="table-header top-color-green">云桌面</div>
                        <div class="table-body height-auto">
                            <img class="img self-2" :src="require('@/assets/service/Group121.png')"/>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="table-header top-color-purple">终端</div>
                        <div class="table-body height-auto">
                            <img class="img self-1" :src="require('@/assets/service/Group126.png')"/>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div class="white-bg-container gray">
            <div class="main-container">
                <p class="title">方案性能<span class="font">SOLUTION PERFORMANCE</span></p>
                <PlanPerformance :arr="['低延时，高品质', '多类终端适配、跨平台', '易接入、快速部署', '统一运维管理']"></PlanPerformance>
            </div>
        </div>
        <!-- 热门方案 -->
        <div class="white-bg-container">
            <div class="main-container">
                <p class="title">热门方案<span class="font">POPULAR PLANS</span></p>
                <PlanPoular :solutionList="solutionList"></PlanPoular>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive } from 'vue';
import PlanFeatureItem from '@/components/PlanFeatureItem.vue';
import PlanPerformance from '@/components/PlanPerformance.vue';
import BannerColorBg from '@/components/BannerColorBg.vue';
import PlansAdvantage from '@/components/PlansAdvantage.vue';
import PlanPoular from '@/components/PlanPopular.vue'

const topObj = reactive({
    title: '快速接入云上桌面服务',
    desc: '一站式部署高性能企业桌面办公体系，统一管控运维、智能化高效算力调度。广泛被应用于对安全性要求高、性能要求极致的办公、设计、娱乐、影视、教育等领域，灵活服务模式。',
    iconUrl: 'solutionCloudGame/云电脑.png'
})

const imageContentList = reactive([
    {title: '专业体验', showUrl: 'solutionCloudGame/Group56_1.png', bgUrl: 'solutionCloudGame/Group56.png',subtitle: '专业体验', subContent: '方案适配办公、设计、影视、教育等专业场景'},
    {title: '安全可控', showUrl: 'solutionCloudGame/Group55_1.png', bgUrl: 'solutionCloudGame/Group55.png',subtitle: '安全可控', subContent: '专研技术保障，工作不中断、数据不丢失'},
    {title: '灵活高效', showUrl: 'solutionCloudGame/Group54_1.png', bgUrl: 'solutionCloudGame/Group54.png',subtitle: '灵活高效', subContent: '随取随用，多端接入，全端支持，简单接入'},
    {title: '协同管理', showUrl: 'solutionCloudGame/Group53_1.png', bgUrl: 'solutionCloudGame/Group53.png',subtitle: '协同管理', subContent: '协同办公、教学、管理轻松'},
    {title: '降本增效', showUrl: 'solutionCloudGame/Group52_1.png', bgUrl: 'solutionCloudGame/Group52.png',subtitle: '降本增效', subContent: '大幅降低硬件规格要求'},
])

const featureContentList = reactive([
    {title: '多终端适配', showUrl: 'solutionCloudGame/多终端.png', content: '方案支持多类型终端设备无忧升级。'},
    {title: '简单化部署', showUrl: 'solutionCloudGame/简单.png', content: '一站式服务，部署步骤简单高效。'},
    {title: '高性能体验', showUrl: 'solutionCloudGame/高性能.png', content: '低硬件规格也能体验极致性能。'},
    {title: '轻量化管理', showUrl: 'solutionCloudGame/轻量化.png', content: '资源调度灵活、运维管理统一。'},
])

const solutionList = reactive([
    {title: '云桌面', url: '/solutionCloudGame/游戏.png', 
        adv: ['简单升级即可提高性能，使用大型软件', '高效协同，支持多种设备，提高办公效率', '云端和本地设备数据打通，数据实时共享，防泄漏'], 
        bad: ['传统办公环节和软件管理不统一，协同性差','普通电脑更换或迭代硬件升级成本高','多种类型终端运行环境不统一']},
    {title: '云网吧', url: '/solutionCloudGame/平台.png', 
        adv: ['降低硬件、耗电、带宽在内的运营成本', '灵活调动算力、按需分配，维护简单', '超低延迟云终端极致体验'], 
        bad: ['传统网吧运营成本高，对“轻资产运营”、“高弹性运营”需求强烈','算力配置不合理，主机配置不灵活，网络稳定性差','数据云端管理容易丢失、被盗']},
    {title: '电竞酒店', url: '/solutionCloudGame/微端.png', 
        adv: ['轻资产、高性能的一站式服务，降本增效', '运行环境统一、运维服务统一', '发烧级性能、超低延迟的极致体验'], 
        bad: ['传统电竞酒店入场门槛高，成本不可控','软硬件采购、网络环境搭建、维护保养繁琐','高功耗设备需要申请消防证']}
])

</script>
<style scoped lang="less">

.white-bg-container {
    background: #fff;
    padding-bottom: 60px;
    &.gray {
        background-color: rgba(5, 64, 250, 0.08);
    }
    .main-container {
        width: 1170px;
        margin: auto;
        .title {
            font-weight: 600;
            font-size: 24px;
            color: #3A466A;
            line-height: 28px;
            padding: 40px 0px 20px 0px;
            span {
                font-size: 16px;
                color: #3A466A;
                line-height: 28px;
                padding: 8px;
            }
        }
    }

}

.title-line-container {
    min-width: 944px;
    width: 945px;
    margin: auto;
    display: flex;
    align-items: center;
    span {
        flex-shrink: 0;
        font-weight: 500;
        font-size: 22px;
        color: #0540FA;
        line-height: 28px;
        text-align: center;
    }
}
.line-with-arrow {
    width: 230px;
    position: relative;
    border: 1px solid;
    border-image: linear-gradient(90deg, rgba(5, 64, 250, 1), rgba(0, 230, 188, 1)) 2 2;
    height: 0; /* 高度为0，因为是垂直线 */
    margin: 30px;
    &.green-start {
        border-image: linear-gradient(90deg, rgba(0, 230, 188, 1), rgba(127, 69, 255, 1)) 2 2;
    }
    .right-icon {
        right: -2px;
        position: absolute;
        width: 10px;
        height: 10px;
        border-top: 1px solid #00E6BC;
        border-right: 1px solid #00E6BC;
        transform: rotate(45deg);
        margin-right: -10px;
        margin-left: 1px;
        &.right-green {
            top: -5px;
            right: 10px;
        }
        &.left-blue {
            top: -5px;
            left: 10px;
            margin-left: -10px;
            margin-right: 1px;
            transform: rotate(-45deg);
            border-top: 1px solid #0540FA;
            border-left: 1px solid #0540FA;
            border-right: none;
        }
        &.left-green {
            top: -5px;
            left: 10px;
            margin-left: -10px;
            margin-right: 1px;
            transform: rotate(-45deg);
            border-top: 1px solid #00E6BC;
            border-left: 1px solid #00E6BC;
            border-right: none;
        }
        &.right-purple {
            top: -5px;
            right: 10px;
            border-top: 1px solid #7F45FF;
            border-right: 1px solid #7F45FF;
        }
    }
}
.table-container {
    .table-header {
        height: 64px;
        background: #FFFFFF;
        border-radius: 0px 0px 0px 0px;
        border: 1px solid #AEB8D8;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #3A466A;
        line-height: 64px;
        text-align: center;
        border-top: 4px solid #2F80FF;
        border-radius: 4px 4px 0px 0px;
        &.marginTop30 {
            margin-top: 30px;
        }
        &.top-color-green {
            border-top: 4px solid #00EEC2;
        }
        &.top-color-purple {
            border-top: 4px solid #7F45FF;
        }
    }
    .table-body {
        background: #F0F4FF;
        border-radius: 0px 0px 4px 4px;
        border: 1px solid #AEB8D8;
        box-sizing: border-box;
        height: 108px;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
            display: block;
            width: 196px;
            height: 42px;
            margin: auto;
            &.self-1{
                width: 273px;
                height: 42px;
            }
            &.self-2{
                width: 196px;
                height: 110px;
            }
        }
        &.height-auto {
            height: 310px;
        }
    }
}

</style>