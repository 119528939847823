import {createRouter, createWebHashHistory} from 'vue-router';
import Home from '@/pages/Home.vue';
import About from '@/pages/About.vue';
import NewsDetail from '@/pages/NewsDetail.vue';
import Enterprise from '@/pages/Enterprise.vue';
import Service from '@/pages/Service.vue';
import SolutionCloudComputer from '@/pages/SolutionCloudComputer.vue';
import SolutionCloudGame from '@/pages/SolutionCloudGame.vue';
import SolutionCloudAi from '@/pages/SolutionCloudAi.vue';
const routes = [
	{
		path:'/',
		name:'Home',
		component: Home
	},
	{
		path:'/solution',
		name:'Solution',
		component: SolutionCloudGame
	},
	{
		path:'/computer',
		name:'SolutionComputer',
		component: SolutionCloudComputer
	},
	{
		path:'/aigc',
		name:'SolutionAi',
		component: SolutionCloudAi
	},
	{
		path:'/service',
		name:'Service',
		component: Service
	},
	{
		path:'/news',
		name:'Enterprise',
		component: Enterprise
	},
	{
		path:'/about',
		name:'About',
		component: About
	},
	{
		path:'/detail',
		name:'NewsDetail',
		component: NewsDetail
	},
]
const router = createRouter({
	history: createWebHashHistory(),
	routes,
	// 是否应该禁止尾部斜杠。默认为假
 	strict: true,
  	scrollBehavior: () => ({ left: 0, top: 0 }),
})
export default router;