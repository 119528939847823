<template>
    <div style="width: 1170px; margin: 0px auto;">
      <el-row>
        <el-col :span="8" v-for="(item,index) in list" :key="index">
          <div @mouseenter="listItem = item; listIndex=index" class="btn-cont" :class="{active: listIndex==index}">
            <img style="margin-top:44px; width: 64px; height: 64px;" :src="require('../assets/' + item.img)" alt="">
            <p class="btn-man-title-t">{{ item.title }}</p>
            <p class="btn-man-title-b">{{ item.sub_title }}</p>
            <div class="arrow" v-show="listIndex==index"></div>
          </div>
        </el-col>
      </el-row>
      <div class="btn-man-content">
        <div class="man-left">
          <p class="btn-text">{{listItem.dec}}</p>
          <div class="btn-tu-box">
            <div v-for="(item,index) in listItem.tab" :key="index" class="btn-tu">
              <img style="width:32px;height:32px;margin-right:10px;" :src="require('../assets/' + item.img)" alt="">
              <span>{{item.name}}</span>
            </div>
          </div>
          <button class="ljgd" @click="router.push(list[listIndex]['path'])">了解更多</button>
        </div>
        <img class="man-right" :src="require('../assets/' + listItem.pice)">
      </div>
    </div>
</template>

<script>
export default {
    name: 'PartTwo',
};
</script>
<script setup>
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router'
const router = useRouter()

const list = reactive([
  {
    img: '电脑2x.png', path: '/computer', title: "云电脑", pice: '云电脑.webp', sub_title: "快速接入云上桌面服务", dec: "快速创建云上桌面环境，一站式部署安全、高性能、低成本的企业桌面办公体系，统一管控运维、智能化高效算力调度。广泛被应用于对安全性要求高、性能要求极致的办公、设计、娱乐、影视、教育等领域，灵活服务模式。",
    tab: [
      { img: "桌面.svg", name: "云桌面" },
      { img: "网吧.svg", name: "云网吧" },
      { img: "酒店.svg", name: "云电竞酒店" },
    ]
  },
  {
    img: "游戏2x.png", path: '/solution', title: "云游戏", pice: "云游戏.webp", sub_title: "疾速构建全端云游戏能力", dec: "专为云游戏设计，可快速将游戏转化为云游戏，或打造云原生游戏。画质至高可达4K@120帧，运行可靠性超过99.99%，支持游戏自助云化、运行参数定制，提供跨终端体验的云原生游戏体验。",
    tab: [
      { img: "云平台.svg", name: "云游戏平台" },
      { img: "微端.svg", name: "云微端" },
      { img: "游戏_large.svg", name: "精品云游戏" },
      { img: "直播.svg", name: "互动直播" },
      { img: "发行.svg", name: "游戏云发行" },
    ]
  },
  {
    img: "AIGC2x.png", path: '/aigc', title: "AIGC", pice: "AIGC_large.webp", sub_title: "快捷创建定制化AI大模型", dec: "专为快速创建和部署模型研发，提供多规格、多样化智能算力，拥有大规模分布式训练、推理加速能力，助力千行百业数字化、智能化升级。",
    tab: [ 
      { img: "大模型.svg", name: "企业大模型" },
      { img: "内容.svg", name: "内容制作" },
      { img: "优化.svg", name: "模型推理优化" },]
  }
])
const listItem = ref(list[0])
const listIndex = ref(0)

</script>

<style lang="less" scoped>
.btn-cont {
  position: relative;
  height: 220px;
  box-sizing: border-box;
  text-align: center;
}
.btn-cont.active {
  background: linear-gradient(
    180deg,
    rgba(5, 64, 250, 0) 0%,
    rgba(5, 64, 250, 0.08) 100%
  );
  border-radius: 0px 0px 0px 0px;
}
.arrow {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: #0540fa;
  animation: highlight-animation 0.3s 1;
  animation-timing-function: ease-in-out;
}
@keyframes highlight-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.btn-cont.active:after {
  z-index: 1;
  content: "";
  position: absolute;
  bottom: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #0540fa;
  animation: highlight-animation1 0.3s 1;
  animation-timing-function: ease-in-out;
}
@keyframes highlight-animation1 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.btn-man-title-t {
  margin-top: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 20px;
  color: #3a466a;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.btn-man-title-b {
  margin-top: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #969eba;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.btn-man-content {
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f0f4ff;
}
.man-left {
  flex: 1;
}
.man-right {
  flex-shrink: 0;
  max-width: 440px;
}
.btn-tu-box {
  display: flex;
  flex-wrap: wrap;
}
.btn-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #485069;
  line-height: 36px;
  font-style: normal;
  text-transform: none;
  padding-bottom: 20px;
}
.btn-tu {
  width: calc((100% - 60px)/3);
  display: flex;
  padding: 15px 14px;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #c6d3ff;
  opacity: 0.5;
  box-sizing: border-box;
  margin-right: 30px;
  margin-bottom: 16px;
}
.btn-tu:nth-child(3n){
  margin-right: 0px;
}
.ljgd {
  position: absolute;
  bottom: 30px;
  width: 172px;
  height: 52px;
  background: #0540fa;
  margin-top: 22px;
  border-radius: 4px 4px 4px 4px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  line-height: 18px;
  &:active{
      box-shadow: 3px 2px 1px 0 rgba(255, 255, 255, 0.7);
  }
}
</style>
