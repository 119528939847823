<template>
<div class="img-container">
    <div class="img-content-box" @mouseenter="showIndex = index" v-for="(item, index) in attrs?.imageContentList" :key="item.title">
        <div class="show" v-show="showIndex === index">
            <img :src="require('@/assets/' + item.showUrl)" />
            <div class="content-box">
                <div class="text">{{ item?.subtitle }}</div>
                <div class="content">{{ item?.subContent }}</div>
            </div>
        </div>
        <div class="bg-show" v-show="showIndex!=index" :style="{backgroundImage: 'url(' +  require('@/assets/' + item.bgUrl) + ')'}">
            <span>{{ item.title }}</span>
        </div>
    </div>
</div>
</template>
<script setup>
import { ref, useAttrs } from 'vue';

const attrs = useAttrs()
const showIndex = ref(0)

</script>
<style scoped lang="less">
@keyframes highlight-animation {
  0% {
    opacity: 0;
    transform: translate(-120px);
  }
  100% {
    opacity: 1;
    transform: translate(0px);
  }
}
@keyframes highlight-animation1 {
  0% {
    transform: translate(80px);
  }
  100% {
    transform: translate(0px);
  }
}
.img-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .img-content-box {
        margin-right: 30px;
        &:nth-child(5n){
            margin-right: 0px;
        }
        .show {
            position: relative;
            animation: highlight-animation 0.4s 1;
            animation-timing-function: ease-in-out;
            .content-box {
                top: 0;
                position: absolute;
                padding: 30px;
                .text {
                    font-size: 22px;
                    color: #FFFFFF;
                    line-height: 32px;
                }
                .content {
                    margin-top: 8px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }
        }
        .bg-show {
            width: 170px;
            height: 360px;
            border-radius: 8px;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 18px;
            color: #FFFFFF;
            line-height: 32px;
            background-color: #1e42f0;
            animation: highlight-animation1 0.4s 1;
            animation-timing-function: ease-in-out;
        }
    }
}
</style>