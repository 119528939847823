<template>
    <div class="lead">
      <div class="lead-content" v-for="(item,index) in leadList" :key="index">
        <div class="lead-top" style="display:flex; align-items: center; justify-content: space-between;">
          <div>
            <p class="lead-one-p">{{item.name}}</p>
            <p class="lead-two-p font">{{item.title.toLocaleUpperCase()}}</p>
          </div>
          <img style="width:110px; margin-right:33px" :src="require('../assets/' + item.img)" alt="">
          <div class="line"></div>
        </div>
        <div class="lead-bot-text">{{item.text}}</div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'PartFour',
};
</script>
<script setup>
import { reactive } from 'vue';

const leadList = reactive([
  { name: '云边协同', title: 'CLOUD-EDGE COLLABORATION', img: '协同.svg', text: "边缘计算节点与部署在全国的云服务器实现云边协同，既可以将云端能力下沉至边缘，又能够发挥强大的计算性能。" },
  { name: '专用方案', title: 'DEDICATED SOLUTION', img: '方案.svg', text: "根据终端场景采用定制化硬件、自研系统软件，构造专用的基础设施解决方案。提供全功能控制台和云 API 接口，可实现对边缘模块和实例的统一管理、操作、云监控，7*24技术支持服务。" },
  { name: '成本优势', title: 'Cost advantage', img: '成本.svg', text: "支持按需创建使用资源，根据业务波动灵活调整用量，节省大量资本投入和占用成本。" },
  { name: '弹性灵活', title: 'Flexible', img: '灵活.svg', text: "支持大规模弹性并发与按量计费，可在几分钟内完成模块和实例的调整，根据业务需求波动灵活调整。" },
  { name: '时延保障', title: 'Delay guarantee', img: '时效.svg', text: "根据网络传输协议特点，智能优化端到端的传输全链路，形成智能化的网络传输优化方案，实现在复杂网络环境下提供稳定可靠的服务体验。" },
  { name: '安全可靠', title: 'Safe and reliable', img: '可靠.svg', text: "自研技术保障云上资源和传输的安全性，提供强大的平台可用性和专业级的安全防护。" },
])
</script>

<style lang="less" scoped>

@keyframes animate {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
.lead {
  cursor: default;
  margin: 0px auto;
  width: 1170px;
  padding-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
}
.lead-content {
  position: relative;
  width: calc((100% - 60px)/3);
  height: 280px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  margin-right: 30px;
  margin-top: 30px;
  
  .line {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: transparent;
    border-radius: 0px 0px 4px 4px;
  }
  &:hover .line {
    background: linear-gradient(to right, rgba(5, 64, 250, 0.1), #0540FA);
    animation: animate 0.3s 1;
    animation-timing-function: ease-in-out;
  }
}
.lead-content:nth-child(3n) {
  margin-right: 0px;
}
.lead-top {
  height: 150px;
  background: linear-gradient(180deg, #dde6ff 0%, #ffffff 100%);
  border-radius: 8px 8px 8px 8px;
}
.lead-one-p {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 20px;
  color: #3a466a;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 10px 0 0 20px;
}
.lead-two-p {
  margin: 2px 0 0 20px;
  font-family: DingTalk Sans, DingTalk Sans;
  font-weight: 400;
  font-size: 12px;
  color: #3a466a;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.lead-bot-text {
  padding: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #969eba;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.lead-nav {
  margin: 0 auto;
  background: #f4f7ff;
}
</style>
