<template>
  <div>
    <div class="foot-bom">
      <div style="width:1170px;margin:0 auto;display:flex;">
        <div class="foot-bom-left">
          <img style="width:120px;height:27px;margin-top:40px" src="../assets/资源.svg" alt="">
          <div class="foot-email">邮箱</div>
          <a style="color:#45DAE8;font-size:12px;" href="#">business@fdcompute.com</a>
          <div class="putonfile">CopyRight © 苏ICP备2021038676号</div>
        </div>
        <div class="foot-bom-right">
          <div class="wanshi">
            <div class="ftitle" v-for="(item,index) in aboutList" :key="index">
              <div style="margin-bottom:26px;">{{item.name}}</div>
              <div class="f-t-title" v-for="(_item,_index) in item.list" :key="_index" @click="router.push(_item.path)">{{_item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop :bottom="100">
        <div
            style="
                height: 100%;
                width: 100%;
                padding: 10px;
                background-color: var(--el-bg-color-overlay);
                box-shadow: var(--el-box-shadow-lighter);
                display: flex;
                justify-content: center;
                border-radius: 50%;
                color: #1989fa;
                transform: scale(1.2);
            "
        >
            <el-image style="width: 18px" :src="require('../assets/Frame.svg')" />
        </div>
    </el-backtop>
  </div>
</template>
<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter()
const aboutList = reactive([
  {
    name: '解决方案',
    list: [{ name: '云游戏', path: 'solution' }, { name: '云电脑', path: '/computer' }, { name: 'AIGC', path: '/aigc' }]
  },
  {
    name: '产品服务',
    list: [{ name: '产品概览', path: '/service' }]
  },
  {
    name: '企业动态',
    list: [{ name: '最新动态', path: '/news' }]
  },
  {
    name: '关于我们',
    list: [{ name: '联系我们', path: '/about' }]
  }
])
</script>
<style scoped>
.foot-bom {
  width: 100%;
  height: 224px;
  background: #0e1420;
}
.foot-bom-left {
  width: 50%;
  padding-left: 13px;
}
.foot-email {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 49px;
}
.putonfile {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 28px;
}
.foot-bom-right {
  width: 50%;
  color: #fff;
  padding-right: 13px;
}
.wanshi {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.ftitle {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
}
.f-t-title {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-bottom: 8px;
  cursor: pointer;
}
</style>