<template>
    <div>
        <!-- 头部 -->
        <div class="top-container">
            <div class="about-top">
                <div class="about-top-text">
                    <div class="atone">软硬一体的智能边缘云服务产品</div>
                    <div class="attwo">低时延、高可用、低成本。一站式接入，7*24小时 技术支持。</div>
                </div>
                <img class="main-icon" :src="require('@/assets/service/云产品.png')"/>
                <img class="cover-icon" :src="require('@/assets/service/前景覆层.png')"/>
            </div>
        </div>
        <!-- 云游戏 -->
        <div class="banner-container">
            <div class="banner cloud">
                <div class="banner-content">
                    <div style="display: flex;">
                        <div class="banner-text">云游戏</div>
                        <span class="banner-span font">CLUND GAMING</span>
                    </div>
                    <div class="title">即刻构建全端云游戏能力 </div>
                    <div class="banner-two">专为云游戏设计，可快速将游戏转化为云游戏，或打造云原生游戏。支持游戏自助云化、运行参数定制，提供跨终端体验的云原生游戏体验。</div>
                </div>
                <!-- <img class="main-icon" :src="require('@/assets/service/云游戏.png')"/> -->
            </div>
        </div>
        <div class="white-bg-container">
            <div class="main-container">
                <div class="content-box">
                    <div class="bg-text-box" v-for="item in contentList" :key="item.title" :style="{backgroundImage: 'url(' +  require('@/assets/service/' + item.bgUrl) + ')'}">
                        <span>{{ item.title }}</span>
                    </div>
                </div>
                <div class="black-title">如何实现云游戏？</div>
                <div class="black-content">利用云计算、虚拟化、超高清音视频串流等技术，让玩家摆脱硬件设备束缚。将原本应该在本地进行的游戏运算、渲染、存储等都放在云端服务器执行，通过浏览器或轻量级客户端，即可畅玩高品质游戏。</div>
                <img :src="require('@/assets/service/Group111.png')">
            </div>
        </div>

        <div class="blue-bg-container auto">
            <div class="main-container">
                <div class="title">产品优势</div>
                <div class="content-text">定制服务器结合全栈自研技术，对标专业电竞级体验 </div>
                <div class="content-text">超高清，低时延，稳定流畅，实现至高4K@120帧 快速接入，超高性价比，兼容X86+ARM 生态 </div>
                <div class="content-text">即点即玩，支持手机、PC、H5、TV跨终端进入</div> 
                <div class="content-text">云到端全打通，登录、支付、输入…</div>
                <div class="connection-btn" @click="UserInfoModalRef.showModal()">预约方案</div>
                <img class="bgImage" :src="require('@/assets/service/装饰1.png')">
            </div>
        </div>

        <!-- 云电脑 -->
        <div class="banner-container">
            <div class="banner">
                <div class="banner-content">
                    <div style="display: flex;">
                        <div class="banner-text">云电脑</div>
                        <span class="banner-span font">CLUND CUMPUTER</span>
                    </div>
                    <div class="title">快速接入云上桌面服务 </div>
                    <div class="banner-two">一站式部署高性能企业桌面办公体系，统一管控运维、智能化高效算力调度。广泛被应用于对安全性要求高、性能要求极致的办公、设计、娱乐、影视、教育等领域，灵活服务模式。</div>
                </div>
                <img class="main-icon" :src="require('@/assets/service/云电脑.png')"/>
            </div>
        </div>
        <div class="white-bg-container">
            <div class="main-container">
                <div class="content-box">
                    <div class="bg-text-box self-width" v-for="item in contentComputerList" :key="item.title" :style="{backgroundImage: 'url(' +  require('@/assets/service/' + item.bgUrl) + ')'}">
                        <span>{{ item.title }}</span>
                    </div>
                </div>
                <div class="black-title">如何实现云电脑？</div>
                <div class="black-content">通过将桌面、应用、硬件等资源整合上云，向用户提供弹性分配的计算资源，和按需调用的服务模式，使低端设备即刻升级为高端电脑。支持多种终端设备连接，无需购买硬件、数据存储方便、配置灵活。</div>
                <div class="title-line-container">
                    <span>管理资源池</span>
                    <div class="line-with-arrow">
                        <div class="right-icon left-blue"></div>
                        <div class="right-icon right-green"></div>
                    </div>
                    <span>调度服务器</span>
                    <div class="line-with-arrow green-start">
                        <div class="right-icon left-green"></div>
                        <div class="right-icon right-purple"></div>
                    </div>
                    <span>局域网/互联网</span>
                </div>
                <el-row class="table-container" :gutter="30">
                    <el-col :span="8">
                        <div class="table-header">GPU服务器资源池</div>
                        <div class="table-body">
                            <img class="img" :src="require('@/assets/service/Group119.png')"/>
                        </div>
                        <div class="table-header marginTop30">存储资源池</div>
                        <div class="table-body">
                            <img class="img" :src="require('@/assets/service/Group120.png')"/>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="table-header top-color-green">云桌面</div>
                        <div class="table-body height-auto">
                            <img class="img self-2" :src="require('@/assets/service/Group121.png')"/>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="table-header top-color-purple">终端</div>
                        <div class="table-body height-auto">
                            <img class="img self-1" :src="require('@/assets/service/Group126.png')"/>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="blue-bg-container">
            <div class="main-container">
                <div class="title">产品优势</div>
                <div class="content-text">专业体验：专为设计、影视、教育等专业场景设计 </div>
                <div class="content-text">安全可靠：专研技术保障 </div>
                <div class="content-text">灵活高效：随取随用，多端接入</div> 
                <div class="content-text">降本增效：大幅降低硬件规格要求</div>
                <div class="connection-btn" @click="UserInfoModalRef.showModal()">预约方案</div>
                <img class="bgImage" :src="require('@/assets/service/背景装饰2.png')">
            </div>
        </div>

        <!-- AIGC -->
        <div class="banner-container">
            <div class="banner">
                <div class="banner-content">
                    <div style="display: flex;">
                        <div class="banner-text">AIGC</div>
                    </div>
                    <div class="title">快捷创建定制化AI大模型 </div>
                    <div class="banner-two">专为快速创建和部署模型研发，提供多规格、多样化智能算力，拥有大规模分布式训练、推理加速能力，助力千行百业数字化、智能化升级。</div>
                </div>
                <img class="main-icon" :src="require('@/assets/service/AIGC.png')"/>
            </div>
        </div>

        <div class="white-bg-container">
            <div class="main-container">
                <div class="content-box">
                    <div class="bg-text-box self-width" v-for="item in contentAIGCList" :key="item.title" :style="{backgroundImage: 'url(' +  require('@/assets/service/' + item.bgUrl) + ')'}">
                        <span>{{ item.title }}</span>
                    </div>
                </div>
                <div class="black-title">如何创建定制化AI大模型？</div>
                <el-row :gutter="30">
                    <el-col :span="6" v-for="item in contentAIGCResolveList" :key="item.content">
                        <div class="img-content-box">
                            <img class="img" :src="require('@/assets/service/'+ item.bgUrl)"/>
                            <div class="bottom-bg">
                                <div class="title">{{ item.title }}</div>
                                <div class="content">{{ item.content }}</div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div class="blue-bg-container">
            <div class="main-container">
                <div class="title">产品优势</div>
                <div class="content-text">端到端一站式方案：大幅降低技术门槛低，快捷接入 </div>
                <div class="content-text">性能卓越：专研方案提供极致性能 </div>
                <div class="content-text">超高性价比：统一资源调度，超高复用率</div> 
                <div class="connection-btn" @click="UserInfoModalRef.showModal()">预约方案</div>
                <img class="bgImage" :src="require('@/assets/service/背景装饰2.png')">
            </div>
        </div>
        <div class="banner-container">
            <div class="banner">
                <div class="banner-content">
                    <div style="display: flex;">
                        <div class="banner-text">简单3步 快速接入</div>
                    </div>
                    <div class="banner-two" style="margin-top: 17px;">从方案选择到上线运营，我们提供全流程的专业服务。</div>
                </div>
                <el-row :gutter="30">
                    <el-col :span="8">
                        <div class="bottom-banner-box">
                            <div class="title-sub">01 业务探索</div>
                            <div class="content">根据业务需要选择合适的方案</div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="bottom-banner-box">
                            <img class="img-start" :src="require('@/assets/service/arrow.png')">
                            <img class="img-end" :src="require('@/assets/service/arrow.png')">
                            <div class="title-sub">02 方案设计</div>
                            <div class="content">技术专家设计规划成熟方案</div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="bottom-banner-box">
                            <div class="title-sub">03 服务接入</div>
                            <div class="content">技术团队7*24小时提供协助</div>
                        </div>
                    </el-col>
                </el-row>
                <div class="connection-btn blue" @click="UserInfoModalRef.showModal()">预约接入方案</div>
            </div>
        </div>
        <UserInfoModal ref="UserInfoModalRef"/>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import UserInfoModal from '@/components/UserInfoModal.vue';

const UserInfoModalRef = ref()

const getContainerHeight = (height) => {
    const realWidth = window.screen.availWidth
    return ((height / 1440) * realWidth).toFixed(0) + 'px'
}
const contentList = reactive([
    {title: '精品云游戏大作', bgUrl: 'Group110.png'},
    {title: '云游戏平台', bgUrl: 'Group109.png'},
    {title: '云微端', bgUrl: 'Group108.png'},
    {title: '游戏云发行', bgUrl: 'Group107.png'},
    {title: '互动游戏直播', bgUrl: 'Group106.png'},
])
const contentComputerList = reactive([
    {title: '云桌面', bgUrl: 'Group1101.png'},
    {title: '云网吧', bgUrl: 'Group1103.png'},
    {title: '电竞酒店', bgUrl: 'Group1105.png'},
])
const contentAIGCList = reactive([
    {title: '企业大模型定制', bgUrl: 'Group1102.png'},
    {title: '内容制作', bgUrl: 'Group1104.png'},
    {title: '大模型推理优化', bgUrl: 'Group1106.png'},
])

const contentAIGCResolveList = reactive([
    {title: '大模型集成', content: '语言模型、图片模型集成，提供一站式AI大模型接入方案。', bgUrl: '4.png'},
    {title: '私有数据训练', content: '基于企业的私有数据进行大模型训练与微调，打造企业专属的大模型应用。', bgUrl: '2.png'},
    {title: '业务应用', content: '为不同业务场景定制服务，打造AI内容创作、AI运营中台、AI客服、AI营销等业务应用服务。', bgUrl: '1.png'},
    {title: '定制开发', content: '基于丰富的政企、金融、制造、科技领域定制化大模型应用开发经验，可针对行业特点和业务需求，提供一站式服务。', bgUrl: '3.png'},
])


</script>

<style scoped lang="less">
.bottom-banner-box {
    position: relative;
    width: 370px;
    height: 104px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    margin: 30px 0px 60px;

    .img-start, .img-end {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: -23px;
    }
    .img-end {
        left: auto;
        right: -23px;
    }

    .title-sub {
        margin: 0px;
        font-weight: 500;
        font-size: 20px;
        color: #0540FA;
        line-height: 28px;
    }
    .content {
        margin-top: 12px;
        font-weight: 400;
        font-size: 14px;
        color: #969EBA;
        line-height: 24px;
    }
}

.title-line-container {
    min-width: 944px;
    width: 945px;
    margin: auto;
    display: flex;
    align-items: center;
    span {
        flex-shrink: 0;
        font-weight: 500;
        font-size: 22px;
        color: #0540FA;
        line-height: 28px;
        text-align: center;
    }
}
.line-with-arrow {
    width: 230px;
    position: relative;
    border: 1px solid;
    border-image: linear-gradient(90deg, rgba(5, 64, 250, 1), rgba(0, 230, 188, 1)) 2 2;
    height: 0; /* 高度为0，因为是垂直线 */
    margin: 30px;
    &.green-start {
        border-image: linear-gradient(90deg, rgba(0, 230, 188, 1), rgba(127, 69, 255, 1)) 2 2;
    }
    .right-icon {
        right: -2px;
        position: absolute;
        width: 10px;
        height: 10px;
        border-top: 1px solid #00E6BC;
        border-right: 1px solid #00E6BC;
        transform: rotate(45deg);
        margin-right: -10px;
        margin-left: 1px;
        &.right-green {
            top: -5px;
            right: 10px;
        }
        &.left-blue {
            top: -5px;
            left: 10px;
            margin-left: -10px;
            margin-right: 1px;
            transform: rotate(-45deg);
            border-top: 1px solid #0540FA;
            border-left: 1px solid #0540FA;
            border-right: none;
        }
        &.left-green {
            top: -5px;
            left: 10px;
            margin-left: -10px;
            margin-right: 1px;
            transform: rotate(-45deg);
            border-top: 1px solid #00E6BC;
            border-left: 1px solid #00E6BC;
            border-right: none;
        }
        &.right-purple {
            top: -5px;
            right: 10px;
            border-top: 1px solid #7F45FF;
            border-right: 1px solid #7F45FF;
        }
    }
}

.top-container {
    background: linear-gradient( 180deg, #0540FA 0%, #0567FA 100%);
    padding-top: 120px;
    .about-top{
        height: 240px;
        width: 1170px;
        margin: auto;
        position: relative;
        .main-icon {
            position: absolute;
            width: 402px;
            height: 288px;
            bottom: 0px;
            right: 0;
        }
        .cover-icon {
            position: absolute;
            width: 486px;
            height: 216px;
            bottom: -35px;
            right: 0;
        }
    }
}

.atone{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 42px;
    color: #FFFFFF;
    line-height: 49px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
.attwo{
    margin-top: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 26px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    opacity: 0.6;
}
.banner-container {
    width: 100%;
    padding-top: 60px;
    background-color: #e3eafb;
    
}
.banner{
   width: 1170px;
   height: 342px;
   margin: auto;
   position: relative;
   &.cloud {
    background: url('@/assets/service/云游戏1.png') right bottom no-repeat;
   }
   .main-icon {
    width: 320px;
    height: 249px;
    position: absolute;
    right: 0;
    bottom: 30px;
   }
   .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #0540FA;
        line-height: 24px;
        text-align: justified;
        margin: 80px 0px 16px;
   }
}

.banner-content{
    width: 560px;
}
.banner-text{
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 24px;
    color: #3A466A;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-right: 10px;
}
.banner-span{
    font-family: DingTalk Sans, DingTalk Sans;
    font-weight: 400;
    font-size: 16px;
    color: #3A466A;
    line-height: 28px;
    font-style: normal;
    text-transform: none;
    opacity: 0.6;
}
.banner-two{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #969EBA;
    line-height: 24px;
    text-align: justified;
    font-style: normal;
    text-transform: none;
}

.white-bg-container {
    background: #fff;
    padding: 60px 0px;
    .main-container {
        width: 1170px;
        margin: auto;
    }
    .content-box {
        display: flex;
        align-items: flex-start;
        margin-bottom: 60px;
        .bg-text-box {
            width: 200px;
            height: 132px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 43px;
            background-color: #0540FA;
            font-weight: 600;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 28px;
            text-align: center;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-size: cover;
            &:nth-child(5n){
                margin-right: 0px;
            }
            &.self-width {
                width: 370px;
                height: 132px;
                &:nth-child(3n){
                    margin-right: 0px;
                }
            }
        }
    }
    .black-title {
        font-weight: 600;
        font-size: 24px;
        color: #3A466A;
        line-height: 28px;
    }
    .black-content {
        width: 570px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #969EBA;
        line-height: 24px;
        margin: 16px 0px 49px;
    }
    .table-container {
        .table-header {
            height: 64px;
            background: #FFFFFF;
            border-radius: 0px 0px 0px 0px;
            border: 1px solid #AEB8D8;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #3A466A;
            line-height: 64px;
            text-align: center;
            border-top: 4px solid #2F80FF;
            border-radius: 4px 4px 0px 0px;
            &.marginTop30 {
                margin-top: 30px;
            }
            &.top-color-green {
                border-top: 4px solid #00EEC2;
            }
            &.top-color-purple {
                border-top: 4px solid #7F45FF;
            }
        }
        .table-body {
            background: #F0F4FF;
            border-radius: 0px 0px 4px 4px;
            border: 1px solid #AEB8D8;
            box-sizing: border-box;
            height: 108px;
            display: flex;
            align-items: center;
            justify-content: center;
            .img {
                display: block;
                width: 196px;
                height: 42px;
                margin: auto;
                &.self-1{
                    width: 273px;
                    height: 42px;
                }
                &.self-2{
                    width: 196px;
                    height: 110px;
                }
            }
            &.height-auto {
                height: 310px;
            }
        }
    }

    .img-content-box {
        position: relative;
        .img {
            position: absolute;
            top: -55px;
            left: 0;
            right: 0;
            margin: auto;
            width: 180px;
            height: 180px;
        }
        .bottom-bg {
            box-sizing: border-box;
            height: 291px;
            margin-top: 55px;
            border-radius: 4px;
            background: #F0F4FF;
            text-align: center;
            .title {
                padding-top: 135px;
                font-weight: 500;
                font-size: 18px;
                color: #3A466A;
                line-height: 24px;
                text-align: center;
            }
            .content {
                padding: 16px 20px 0px;
                font-weight: 400;
                font-size: 14px;
                color: #969EBA;
                line-height: 24px;
            }
        }
    }
}

</style>
<style scoped lang="less">
.blue-bg-container {
    background: #0540FA;
    width: 1170px;
    margin: 0px auto 60px;
    .main-container {
        padding: 60px 40px 40px;
        width: 1170px;
        margin: auto;
        position: relative;
        border-radius: 4px;
        .title {
            font-weight: 600;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 28px;
            margin-bottom: 30px;
        }
        .content-text {
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 24px;
            &::before {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background: #fff;
                margin-right: 5px;
                display: inline-block;
                margin-bottom: 3px;
            }
        }
        .bgImage{
            top: 0;
            position: absolute;
            right: 0;
        }
    }
}
.connection-btn {
    margin-top: 30px;
    // margin-top: 54px;
    box-sizing: border-box;
    width: 96px;
    cursor: pointer;
    padding: 6px 0px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 16px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FFFFFF;
    &:active{
        box-shadow: 3px 3px 2px 0 rgba(255, 255, 255, 0.7);
    }
    &.blue {
        background: #0540FA;
        color: #FFFFFF;
        width: 206px;
        padding: 16px;
        font-size: 16px;
        margin: 0px auto 0px;
    }
}
</style>
